import axios from 'axios';

import { AUTH_BASE_URL, APP_BASE_URL } from './config';

const headers = {
  'Content-Type': 'application/json'
};

const authNetworkManager = axios.create({
  baseURL: AUTH_BASE_URL,
  headers
});

authNetworkManager.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('API Res', res);
    }
    // res.data = keysToCamel(res.data);
    return res;
  },
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('API Error', error);
    }
    if (error.response) {
      // error.response.data = keysToCamel(error.response.data);
      // console.log(error.response.data);
    }

    return Promise.reject(error);
  }
);

const appNetworkManager = axios.create({
  baseURL: APP_BASE_URL,
  headers
});

appNetworkManager.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('API Res', res);
    }
    // res.data = keysToCamel(res.data);
    return res;
  },
  (error) => {
    if (process.env.NODE_ENV !== 'production') {
      // console.log('API Error', error);
    }
    if (error.response) {
      // error.response.data = keysToCamel(error.response.data);
      // console.log(error.response.data);
    }

    return Promise.reject(error);
  }
);

export { authNetworkManager, appNetworkManager };
