import { Divider } from 'rsuite';
import { UPLOAD_FILE_URL } from '../../../../core';
import { base64ToJson } from '../../../../utils/helpers';

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-unused-vars
export const SeekerCourseBackgroundView = ({ data, canSeeFull }: any) => {
  return (
    <>
      {data?.length ? (
        <div className="w-full">
          <Divider className="text-[20px] font-bold">دوره های گذرانده</Divider>
        </div>
      ) : null}

      <ul style={{ paddingRight: '12px' }}>
        {data?.map((course: any) => {
          return (
            <li key={course.id} className="mb-3">
              <p className="text-[16px] px-4 py-2 bg-[--rs-primary-50] flex gap-2 text-[--rs-primary-800] rounded-md">
                <div>
                  <strong>{course.CourseName}</strong> {' - '}
                  <strong>{course.InstitutionName}</strong>
                  <strong className="text-[--rs-primary-500]">
                    {' '}
                    {base64ToJson(course.CertificationPic)[0]?.url ? '(دارای گواهی)' : ''}
                  </strong>
                </div>
                {canSeeFull && base64ToJson(course.CertificationPic)[0]?.url ? (
                  <a
                    target="_blank"
                    href={`${UPLOAD_FILE_URL}?${base64ToJson(course.CertificationPic)[0]?.url}&Attachment=False`}
                    className="text-primary hover:text-primary-dark"
                    rel="noreferrer">
                    مشاهده/دریافت گواهی
                  </a>
                ) : (
                  ''
                )}
              </p>
            </li>
          );
        })}
      </ul>
    </>
  );
};
