/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, FlexboxGrid, Input, Radio, RadioGroup } from 'rsuite';
import { useCallback, useState } from 'react';
import { Datepicker } from '@ijavad805/react-datepicker';
import { convertDateToSlashi } from '../../../utils/helpers';
import { APP_BASE_URL } from '../../../core';
import { AdminPayments } from './payments';

const URL_EXPORT_MAPPER: any = {
  مشاوره: '/downloadexcel_consults',
  رزومه: '/downloadexcel_jobseekers',
  رویداد: ''
};
export const AdminPaymentsExcel = () => {
  const [exportType, setExportType] = useState<any>('مشاوره');
  const [timeStampFrom, setTimeStampFrom] = useState<any>(new Date('2022-01-01'));
  const [timeStampTo, setTimeStampTo] = useState<any>(new Date());

  const handleDownloadExcel = useCallback(() => {
    const url =
      APP_BASE_URL +
      URL_EXPORT_MAPPER[exportType] +
      '?StartDate=' +
      timeStampFrom.toISOString().split('T')[0] +
      '&EndDate=' +
      timeStampTo.toISOString().split('T')[0];

    // console.log(url);
    window.open(url, '_blank');
  }, [exportType, timeStampFrom, timeStampTo]);

  return (
    <>
      <h4 className="text-h4 mb-6">دانلود اکسل پرداخت ها</h4>

      <FlexboxGrid>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <label htmlFor="">
            نوع
            <RadioGroup
              name="radio-group-inline"
              inline
              defaultValue="مشاوره"
              value={exportType}
              onChange={(value, _event) => setExportType(value)}>
              <Radio value="مشاوره">مشاوره</Radio>
              {/* <Radio value="رویداد" disabled>
                رویداد
              </Radio> */}
              <Radio value="رزومه">
                رزومه
              </Radio>
            </RadioGroup>
          </label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <label htmlFor="">
            از تاریخ
            <Datepicker
              // className="font-fd"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStampFrom)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStampFrom(new Date(val))}
            />
          </label>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
          <label htmlFor="">
            تا تاریخ
            <Datepicker
              // className="font-fd"
              lang={'fa'}
              theme={'green'}
              format={'D MMMM yyyy'}
              value={convertDateToSlashi(timeStampTo)}
              input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(val: any) => setTimeStampTo(new Date(val))}
            />
          </label>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <div className="flex mt-4 justify-end items-center">
        <Button appearance="primary" className="ml-1" onClick={handleDownloadExcel}>
          دریافت خروجی
        </Button>
      </div>

      <AdminPayments />
    </>
  );
};
