import { Button, Form, Input, Schema } from 'rsuite';
import { useCallback, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IconTextField from '../../../../components/form/icon-textfield';
import { Hide, Show } from 'react-iconly';
import { handleLoginWithPassword } from '../../../../api-handler';
import { useUser } from '../../../../contexts/user';
import { formatOutgoingPhoneNumber } from '../../../../utils/helpers';

const { StringType } = Schema.Types;
const model = Schema.Model({
  Password: StringType('').isRequired('رمز عبور را وارد کنید.')
});

export const LoginPassword = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStep: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step: any;
}) => {
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get('redirect'));
  const { setToken, setUserTrigger, userTrigger } = useUser();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    setLoading(true);
    const res = await handleLoginWithPassword(
      formatOutgoingPhoneNumber(props.step.data.Username),
      formValue.Password
    );
    if (res.ok) {
      // set token and user data
      setToken(res.data.Data.Token, res.data.Data.Username, 'no');
      setUserTrigger(!userTrigger);
      navigate(redirect ?? '/');
    }
    setLoading(false);
  }, [formValue, formError, formRef, props, redirect]);

  //
  const [visible, setVisible] = useState(false);
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      checkTrigger="change"
      readOnly={loading}
      className="mt-8 text-dark">
      <IconTextField
        name="Password"
        label="رمز عبور"
        type={visible ? 'text' : 'password'}
        accepter={Input}
        dir="ltr"
        onIconCLick={() => setVisible(!visible)}
        eyeVisible={visible}
        onIcon={<Show size="small" />}
        offIcon={<Hide size="small" />}
      />
      <div className="flex flex-row justify-between">
        <span
          className="text-b3 text-[--rs-primary-500] mt-1 block cursor-pointer"
          onClick={() => {
            props.setStep({
              ...props.step,
              action: 'otp'
            });
          }}>
          ورود با رمز یکبار مصرف
        </span>
        {/* <span
          className="text-b3 text-[--rs-primary-500] mt-1 block cursor-pointer"
          onClick={() => {
            navigate('/auth/login');
          }}>
          بازگشت
        </span> */}
      </div>
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-4"
        loading={loading}>
        ورود
      </Button>
    </Form>
  );
};
