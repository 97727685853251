/* eslint-disable @typescript-eslint/no-explicit-any */
import { faIR } from 'date-fns-jalali/locale';
import { useEffect, useState } from 'react';
import { FormatDateTime } from '../../utils/helpers';
import { getUsername } from '../../core';
import { useParams } from 'react-router-dom';
import { handleGetTicketMessages } from '../../api-handler';
import { CustomLoader } from '../../components/basic/loader';

export const TicketMessages = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetTicketMessages(id);
      if (res.ok) {
        setData(res.data);
      }
      setLoading(false);
    })();
  }, [id]);
  return (
    <>
      {data ? (
        <div className="rounded-md py-10 px-8 mt-10 border-[1px] border-[--rs-primary-200] bg-[#D9BCC710] max-h-[45vh] overflow-auto pretty-scroll">
          {!data.length ? (
            loading ? (
              <CustomLoader />
            ) : (
              <h5 className="text-center">پیامی موجود نیست!</h5>
            )
          ) : (
            data.map((message: any) => {
              return (
                <div
                  key={message.ID}
                  style={{ boxShadow: '0px 0px 10px 5px #D9BCC730' }}
                  className={`rounded-xl  text-[--rs-primary-600] mb-3 p-5 pb-4 max-w-[500px] min-w-[60%] border-[1px] border-[--rs-primary-200] ${getUsername() == message.Username ? 'ml-auto bg-[--rs-primary-100]' : 'mr-auto bg-[--rs-primary-50]'}`}>
                  <div>{message.Message}</div>
                  <div className="text-[--rs-primary-400] text-[12px] mr-auto ml-0 flex justify-between mt-5">
                    <span>
                      {message.UserInfo ? (
                        <>
                          {message.UserInfo.FirstName} {message.UserInfo.LastName}
                        </>
                      ) : (
                        'پشیبان'
                      )}
                    </span>
                    <span>{FormatDateTime(new Date(message.TimeStamp), faIR)}</span>
                  </div>
                </div>
              );
            })
          )}
        </div>
      ) : null}
    </>
  );
};
