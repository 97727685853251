import { useEffect } from 'react';
import { MEETING_ADDRESSES } from '../../../utils/helpers';
import { Button } from 'rsuite';

export const Rooms = ({ roomNumber }: { roomNumber: number }) => {
  useEffect(() => {
    window.open(MEETING_ADDRESSES.mapper[roomNumber]);
  });

  return (
    <div>
      <h5 className="mb-4 mt-5 text-center">
        درصورتی که به طور خودکار به صفحه جلسه منتقل نشدید روی دکمه زیر کلیک کنید
      </h5>
      <div className="flex justify-center items-center min-h-[20vh]">
        <Button
          appearance="primary"
          size="lg"
          onClick={() => {
            window.open(MEETING_ADDRESSES.mapper[roomNumber]);
          }}>
          انتقال به صفحه جلسه
        </Button>
      </div>
    </div>
  );
};
