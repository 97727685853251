/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from 'rsuite';

export const ConsultantConsultationBackgroundView = ({ data }: any) => {
  return (
    <>
      {data?.length ? (
        <div className="w-full">
          <Divider className="text-[20px] font-bold">سوابق مشاوره ای</Divider>
        </div>
      ) : null}
      <ul style={{ paddingRight: '12px' }}>
        {data?.map((consult: any) => {
          return (
            <li key={consult.id} className="mb-4">
              <div className="flex flex-col justify-between px-4 py-3 bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md mb-2">
                <p className="text-[16px]">
                  مشاوره در زمینه:{' '}
                  <strong>
                    {consult.Field} - {consult.WorkedOn}
                  </strong>
                </p>
                <p className="text-[16px]">
                  نتیجه: <strong>{consult.Result}</strong>
                </p>
                {/* <p className="text-justify">
                  {consult.Description ? <>- {consult.Description}</> : ''}
                </p> */}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
