import { useEffect, useState } from 'react';
import { handleGetRequestedResumesByEmployeeUsername } from '../../api-handler';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { FormatDate, RESUME_REQ_STATUS, sortData } from '../../utils/helpers';
import { Button, Loader } from 'rsuite';
// import { useNavigate } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale';
import { APP_BASE_URL } from '../../core';

export const RequestedResume = () => {
  // const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('ID');
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const res = await handleGetRequestedResumesByEmployeeUsername();
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSortColumn = (sc: any, st: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sc);
      setSortType(st);
    }, 500);
  };

  return (
    <>
      <h4 className="text-h4">درخواست های خرید رزومه</h4>
      <p className="bg-[--rs-primary-50] text-[--rs-primary-500] rounded-md border-[1px] my-2 border-[--rs-primary-300] p-3">
        در این بخش می‌توانید لیست رزومه‌هایی را که برای مشاهده آن‌ها درخواست داده‌اید، مشاهده کنید.
        پس از تأیید کارجو، امکان پرداخت و مشاهده جزئیات رزومه برای شما فعال خواهد شد. همچنین
        می‌توانید وضعیت درخواست‌های ارسال‌شده را پیگیری کنید.
      </p>
      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">درخواستی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام کاربری</HeaderCell>
          <Cell dataKey="JobSeekerUsername" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام و نام خانوادگی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.JobSeekerUsernameInfo}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{FormatDate(rowData.TimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{RESUME_REQ_STATUS[rowData.Status]}</>;
            }}
          </Cell>
        </Column>
        <Column width={160} verticalAlign="middle" align="center">
          <HeaderCell>پرداخت</HeaderCell>
          <Cell dataKey="">
            {(_rowData) => (
              <Button
                appearance="primary"
                size="xs"
                type="button"
                onClick={() => {
                  _rowData.Status == 'EPPending'
                    ? window.open(
                        `${APP_BASE_URL}/buycv?EmployerUsername=${_rowData.EmployerUsername}&JobSeekerUsername=${_rowData.JobSeekerUsername}`
                      )
                    : window.open(`https://panel.cbcs.ir/seekers/${_rowData.JobSeekerUsername}`);
                }}
                disabled={!(_rowData.Status == 'EPPending' || _rowData.Status == 'Paid')}>
                {_rowData.Status === 'EPPending'
                  ? 'پرداخت و دریافت رزومه'
                  : _rowData.Status === 'Paid'
                    ? 'مشاهده'
                    : 'پرداخت و دریافت رزومه'}
              </Button>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
