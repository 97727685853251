/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { faIR } from 'date-fns-jalali/locale';
// import { FormatDateToBeautifulMonthAndYear } from '../../../../utils/helpers';
import { Button, Divider, Tooltip, Whisper } from 'rsuite';
import { useUser } from '../../../../contexts/user';
import { useNavigate, useParams } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale';
import { FormatDate, getAge } from '../../../../utils/helpers';

import InstagramIcon from '../../../../assets/icons/instagram.svg';
import WhatsAppIcon from '../../../../assets/icons/whatsapp.svg';
import LinkedinIcon from '../../../../assets/icons/linkedin.svg';
import TelegramIcon from '../../../../assets/icons/telegram.svg';
import GithubIcon from '../../../../assets/icons/github.svg';
import EmailIcon from '../../../../assets/icons/mail.svg';
import CellPhoneIcon from '../../../../assets/icons/phone.svg';
import { DISABLE_BUYING_RESUME } from '../../../../core';

export const SeekerPersonalView = ({
  data,
  socialData,
  sex,
  canSeeFull,
  canRequestBought,
  isLocked,
  isWorking,
  boughtBy,
  isBought,
  loading,
  handleBuySeeker
}: any) => {
  const { isUserRole, user } = useUser();
  const navigate = useNavigate();
  const { username } = useParams();

  return (
    <>
      <div className="w-full">
        <Divider className="text-[20px] font-bold">اطلاعات پایه</Divider>
      </div>
      {data ? (
        <ol
          style={{ listStyle: 'auto', paddingRight: '12px', fontSize: '16px', lineHeight: '175%' }}>
          {canSeeFull || username == user.Username ? (
            <>
              <div className="font-bold text-[24px] text-[--rs-primary-800] mb-5">
                {data.FirstName} {data.LastName}
              </div>
              <span className="px-2 py-1 font-bold bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                {sex == 'آقا'
                  ? `آقا (${data.Marriage})` + ' - ' + data.MilitaryService
                  : sex == 'خانم'
                    ? `خانم (${data.Marriage})`
                    : data.Sex
                      ? `آقا (${data.Marriage})` + ' - ' + data.MilitaryService
                      : 'خانم'}
              </span>{' '}
              <span>متولد</span>{' '}
              <span className="px-2 py-1 font-bold bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                {FormatDate(data.Birth_Date, faIR)} ({getAge(data.Birth_Date)} سال)
              </span>{' '}
              <br />
              <br />
              <span className="px-2 py-1 font-bold bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                {data.CityE}
              </span>{' '}
              -{' '}
              {data.StateE ? (
                <>
                  {' '}
                  <span className="px-2 py-1 font-bold bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                    {data.StateE}
                  </span>{' '}
                  -{' '}
                </>
              ) : (
                ''
              )}
              <span className="px-2 py-1 font-bold bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                {data.CountryE}
              </span>
            </>
          ) : (
            <>
              <strong>
                {data.Sex ? 'آقا' : 'خانم'} {data.Sex ? '- ' + data.MilitaryService : ''}
              </strong>
              {/* {!isUserRole('Corporation') ? (
              <h5 className="text-center">
                برای مشاهده اطلاعات کارجو پس از درخواست و تایید نقش کسب و کار میتوانید اقدام به خرید
                رزومه کارجو کنید.
              </h5>
            ) : ( */}
              <h5 className="text-center">
                پس از تایید کارجو با پرداخت مبلغ 50,000 تومان میتوانید رزومه کارجو را دریافت کنید
                <br /> حداکثر تا 7 روز پس از خرید، هیچ فردی امکان خرید رزومه این کارجو را نخواهد
                داشت!
                <br />
                با خرید هر 4 رزومه، رزومه 5ام را رایگان دریافت خواهید کرد.
              </h5>
              {/* )} */}
              <h4 className="text-h4 flex justify-center mt-4 items-center text-[--rs-primary-800] mb-3">
                <div>
                  {loading
                    ? null
                    : isBought
                      ? '(خریداری شده)'
                      : isWorking
                        ? '(کارجو استخدام شده)'
                        : isLocked
                          ? '(رزومه درحال برسی است)'
                          : null}
                </div>
                {isBought ? null : DISABLE_BUYING_RESUME ? (
                  'درحال حاضر امکان خرید رزومه وجود ندارد'
                ) : user.isLoggedIn ? (
                  <Whisper
                    placement="top"
                    controlId={`clinic-id-hover-buy`}
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        {isWorking
                          ? 'کارجو استخدام شده'
                          : isLocked
                            ? 'رزومه توسط کسب و کاری دیگر درحال برسی است'
                            : !canRequestBought
                              ? 'درخواست داده شده، در انتطار تایید کارجو'
                              : 'دریافت رزومه'}
                      </Tooltip>
                    }>
                    <Button
                      appearance="primary"
                      onClick={handleBuySeeker}
                      loading={loading}
                      disabled={isLocked || !canRequestBought}>
                      ارسال درخواست دریافت رزومه به کارجو
                    </Button>
                  </Whisper>
                ) : (
                  <Button appearance="default" onClick={() => navigate('/auth/login')}>
                    برای دریافت رزومه ابتدا وارد شوید
                  </Button>
                )}
              </h4>
            </>
          )}
        </ol>
      ) : null}
      {canSeeFull || username == user.Username ? (
        <>
          {socialData || data?.Email || data?.CellPhone ? (
            <>
              {socialData?.Description ? (
                <>
                  <div className="w-full">
                    <Divider className="text-[20px] font-bold">درباره من</Divider>
                  </div>
                  <p>{socialData.Description}</p>
                </>
              ) : null}

              <div className="w-full">
                <Divider className="text-[20px] font-bold">راه های ارتباطی</Divider>
              </div>
              <div className="flex flex-row flex-wrap justify-center font-en gap-3" dir="ltr">
                {data?.Email && (
                  <a
                    href={`mailto:${data.Email}`.toLowerCase()}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#ea433550] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={EmailIcon} className="w-6" alt="Email-icon" />
                      {data.Email}
                    </div>
                  </a>
                )}
                {data?.CellPhone && (
                  <a
                    href={`tel:${data.CellPhone}`.toLowerCase()}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#00940050] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={CellPhoneIcon} className="w-6" alt="CellPhone-icon" />
                      {data.CellPhone}
                    </div>
                  </a>
                )}
                {socialData?.Instagram && (
                  <a
                    href={`https://instagram.com/${socialData.Instagram}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#d13e8750] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={InstagramIcon} className="w-6" alt="Instagram-icon" />
                      {socialData.Instagram}
                    </div>
                  </a>
                )}
                {socialData?.Telegram && (
                  <a
                    href={`https://t.me/${socialData.Telegram}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#28a7e850] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={TelegramIcon} className="w-6" alt="Telegram-icon" />
                      {socialData.Telegram}
                    </div>
                  </a>
                )}
                {socialData?.WhatsApp && (
                  <a
                    href={`https://wa.me/${socialData.WhatsApp}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#0cc14350] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={WhatsAppIcon} className="w-6" alt="WhatsApp-icon" />
                      {socialData.WhatsApp}
                    </div>
                  </a>
                )}
                {socialData?.Github && (
                  <a
                    href={`https://github.com/${socialData.Github}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#00000050] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={GithubIcon} className="w-6" alt="Github-icon" />
                      {socialData.Github}
                    </div>
                  </a>
                )}
                {socialData?.Linkedin && (
                  <a
                    href={`https://www.linkedin.com/in/${socialData.Linkedin}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="flex flex-row gap-2 w-fit text-[20px] bg-[#0077b550] text-[--rs-primary-800] py-2 px-3 text-center rounded-md mb-3">
                      <img src={LinkedinIcon} className="w-6" alt="Linkedin-icon" />
                      {socialData.Linkedin}
                    </div>
                  </a>
                )}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};
