/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { ROUTES } from '../../router';
import { useState } from 'react';
import { useUser } from '../../contexts/user';

export const ProxyPages = () => {
  const navigate = useNavigate();
  const [pages] = useState<any>({
    'خدمات مشاوره مدیریت کسب‌و‌کار': {
      category: 'خدمات مشاوره',
      title: 'مدیریت کسب‌و‌کار',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/business.svg',
      chartPath: './assets/charts/consult.png',
      text: 'با مشاوره مدیریت کسب‌وکار، می‌توانید به راحتی چالش‌های مدیریتی را شناسایی کرده و با راهکارهای عملی و نوآورانه، به بهبود فرآیندها و افزایش بهره‌وری دست یابید.'
    },
    'خدمات مشاوره بازاریابی و فروش': {
      category: 'خدمات مشاوره',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/selling.svg',
      chartPath: './assets/charts/consult.png',
      text: 'با مشاوره فروش، می‌توانید تکنیک‌های مؤثری برای بهبود مهارت‌های فروش، مدیریت روابط با مشتریان و پیاده‌سازی فرآیندهای فروش بهینه یاد بگیرید و در رقابت پیشی بگیرید.'
    },
    'خدمات مشاوره تبلیغات': {
      category: 'خدمات مشاوره',
      title: 'تبلیغات',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/cart.svg',
      chartPath: './assets/charts/consult.png',
      text: 'مشاوره تبلیغات با تحلیل دقیق بازار و رفتار مصرف‌کننده، به شما ابزارها و تکنیک‌های لازم را برای ایجاد پیام‌های جذاب و تاثیرگذار فراهم می‌آورد تا در فضای رقابتی امروز بدرخشید.'
    },
    'خدمات مشاوره منابع انسانی': {
      category: 'خدمات مشاوره',
      title: 'منابع انسانی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/hr.svg',
      chartPath: './assets/charts/consult.png',
      text: 'با مشاوره منابع انسانی، می‌توانید فرآیندهای استخدام، آموزش و ارزیابی عملکرد را بهبود بخشید و به ایجاد تیم‌های کارآمد و سازگار با اهداف کسب‌وکار خود دست یابید.'
    },
    'خدمات مشاوره حسابداری و مالی': {
      category: 'خدمات مشاوره',
      title: 'حسابداری و مالی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/money.svg',
      chartPath: './assets/charts/consult.png',
      text: 'با مشاوره حسابداری، شما می‌توانید به راحتی در دنیای پیچیده مالی حرکت کنید و از راهکارهای بهینه برای کاهش مالیات، افزایش سود و بهبود عملکرد مالی بهره‌مند شوید.'
    },
    'خدمات مشاوره حقوقی': {
      category: 'خدمات مشاوره',
      title: 'حقوقی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/law.svg',
      chartPath: './assets/charts/consult.png',
      text: ''
    },
    'خدمات مشاوره تولید': {
      category: 'خدمات مشاوره',
      title: 'تولید',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/product.svg',
      chartPath: './assets/charts/consult.png',
      text: ''
    },
    'خدمات مشاوره برندسازی': {
      category: 'خدمات مشاوره',
      title: 'برندسازی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/branding.svg',
      chartPath: './assets/charts/consult.png',
      text: 'مشاوره برندسازی به شما کمک می‌کند تا هویت منحصربه‌فرد برندتان را شکل دهید و با استراتژی‌های خلاقانه، برند خود را در ذهن مشتریان ماندگار کنید.'
    },
    'خدمات مشاوره فناوری اطلاعات': {
      category: 'خدمات مشاوره',
      title: 'فناوری اطلاعات',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/programmer.svg',
      chartPath: './assets/charts/consult.png',
      text: 'مشاوره فناوری اطلاعات به شما کمک می‌کند تا با حل مسائل فنی و بهره‌برداری از فناوری‌های روز، عملکرد سیستم‌های خود را بهینه‌سازی کنید و از تکنولوژی به بهترین شکل استفاده نمایید.'
    },
    'خدمات مشاوره سیستم سازی': {
      category: 'خدمات مشاوره',
      title: 'سیستم سازی',
      subtitle: '',
      description: '',
      url: '/consultants',
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/consult.png',
      text: 'مشاوره سیستم‌سازی به شما کمک می‌کند تا با طراحی و پیاده‌سازی ساختارهای کارآمد، فرآیندهای کسب‌وکار خود را بهینه کرده و بهره‌وری را به حداکثر برسانید.'
    },
    //
    'خدمات کاریابی و استخدام جذب نیروی کار': {
      category: 'خدمات کاریابی و استخدام',
      title: 'جذب نیروی متخصص',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/seeker.svg',
      chartPath: './assets/charts/recruitment.png',
      text: 'صاحبان کسب و کار می توانند با بررسی رزومه های موجود در این سامانه به بررسی انتخاب نیروی مورد نظر خود با توجه به ویژگیهای مطرح شده بپردازند و در صورتی که نیروی مناسب خود را یافتند، نسبت به انتخاب و دریافت اطلاعات تماس شخص کارجو اقدام نمایند.'
    },
    'خدمات کاریابی و استخدام جذب نیروی ویژه': {
      category: 'خدمات کاریابی و استخدام',
      title: 'جذب نیروی ویژه',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/seeker.svg',
      chartPath: './assets/charts/recruitment.png',
      text: 'در صورتی است که به هر دلیل صاحب کسب و کار نتواند و یا نخواهد از رزومه های موجود بهره ببرد، در این صورت، درخواست خود را مبنی بر تامین نیرو به صورت ویژه به سامانه ارائه خواهد داد و ما در مجموعه مشاوره نسبت به جستجو، بررسی، مصاحبه و معرفی نیروی با صلاحیت و شایستگی به صاحب کسب و کار اقدام خواهیم کرد. این سامانه مکانی است مناسب برای افراد جویای کار تا بتوانند درخواست خود را در این سامانه ثبت نمایند و از راهکارهای تخصصی کاریابی بهره مند و به صورت تخصصی وارد بازار کار مناسب خود شوند.'
    },
    'خدمات کاریابی و استخدام کاریابی': {
      category: 'خدمات کاریابی و استخدام',
      title: 'کاریابی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqRecruitment.personal,
      imagePath: './assets/img/resume.svg',
      chartPath: './assets/charts/recruitment.png',
      test: 'در کنار شما هستیم تا فرصت‌های شغلی را کشف کنید و بهترین استعدادها را جذب کنید!'
    },

    //
    'پروژه‌های سازمانی': {
      category: 'پروژه‌های سازمانی',
      title: 'پروژه‌های سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'هر کسب و کار برای موفقیت در مسیر خود نیاز دارد تا برخی از برنامه ریزی ها و کارهای خود را برون سپاری کند. گروه مشاوره دکتر رمضانی با نیروهای متخصص و خبره آماده خدمت رسانی به شما در انجام پروژه های سازمانی است تا به بهترین شکل شما را از دغدغه های انجام پروژه ها و تدوین برنامه ها بی نیاز کند و گامی در جهت توسعه کسب و کار شما بردارد.'
    },
    'پروژه‌های سازمانی تحقیقات بازار': {
      category: 'پروژه‌های سازمانی',
      title: 'تحقیقات بازار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'گروه مشاوره دکتر رمضانی تحقیقات بازار شما را انجام می دهد تا با نتایج حاصل از آن، ریسک‌ها را کاهش دهید و تصمیمات خود را بر اساس داده‌های واقعی اتخاذ کنید.'
    },
    'پروژه‌های سازمانی برند سازی': {
      category: 'پروژه‌های سازمانی',
      title: 'برند سازی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/branding.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'خدمات برندینگ ما به شما کمک می‌کند تا برند خود را با قدرت و اعتماد به نفس معرفی کنید.'
    },
    'پروژه‌های سازمانی کمپین تبلیغاتی': {
      category: 'پروژه‌های سازمانی',
      title: 'کمپین تبلیغاتی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/ad.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'با انجام پروژه های کمپین تبلیغاتی از طراحی تا اجرا در کنار شماییم. "کمپین تبلیغاتی که نتایج را رقم می‌زند! با طراحی‌های خلاقانه، برند شما را در بازار می درخشد.'
    },
    'پروژه‌های سازمانی ارزیابی عملکرد': {
      category: 'پروژه‌های سازمانی',
      title: 'ارزیابی عملکرد',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/evaluate.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'ا خدمات ارزیابی عملکرد کارکنان و واحد ها در کنار شماییم تا با دیدی روشن‌تر، تصمیمات استراتژیک بهتری بگیرید و بهره‌وری را افزایش دهید.'
    },
    'پروژه‌های سازمانی طرح کسب‌و‌کار': {
      category: 'پروژه‌های سازمانی',
      title: 'طرح کسب‌و‌کار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'گروه مشاوره کسب و کار به شما کمک خواهد کرد تا طرح توجیهی و طرح کسب و کار خود را به بهترین شکل تنظیم کنید و برنامه های اجرایی و استراتژی های ویژه برای آینده کسب و کار شما ارائه خواهد کرد. '
    },
    'پروژه‌های سازمانی سایر': {
      category: 'پروژه‌های سازمانی',
      title: 'پروژه‌های سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalProjects.personal,
      imagePath: './assets/img/other.svg',
      chartPath: './assets/charts/organizationalProjects.png',
      text: 'هر کسب و کار برای موفقیت در مسیر خود نیاز دارد تا برخی از برنامه ریزی ها و کارهای خود را برون سپاری کند. گروه مشاوره دکتر رمضانی با نیروهای متخصص و خبره آماده خدمت رسانی به شما در انجام پروژه های سازمانی است تا به بهترین شکل شما را از دغدغه های انجام پروژه ها و تدوین برنامه ها بی نیاز کند و گامی در جهت توسعه کسب و کار شما بردارد.'
    },
    //
    'آموزش ها سازمانی': {
      category: 'آموزش های سازمانی',
      title: 'آموزش های سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'آموزش‌های سازمانی به تقویت مهارت‌ها، افزایش بهره‌وری و ارتقاء کارایی تیم‌های شما کمک می‌کند تا به اهداف سازمانی دست یابید."  آموزش‌های سازمانی به صورت ویژه و خاص برای سازمان شما به بهبود مهارت‌های کارکنان، ارتقاء توانمندی‌های مدیریتی و فنی، و افزایش کارایی تیم‌ها کمک می‌کند تا با ایجاد یک محیط کاری مؤثر و هماهنگ، سازمان شما به اهداف استراتژیک خود دست یابد و در بازار رقابتی موفق‌تر عمل کند.'
    },
    'آموزش ها سازمانی مدیریتی': {
      category: 'آموزش های سازمانی',
      title: 'مدیریتی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/manager.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'مدیریت، ذاتی نیست، بلکه باید آن را آموخت و ارتقا داد. آموزش‌هایی که سازمان شما را به سطح جدیدی از موفقیت می‌رساند! با دوره‌های مدیریتی ویژه کارکنان و مدیران، تیم خود را برای چالش‌های آینده آماده کنید.'
    },
    'آموزش ها سازمانی رفتار سازمانی': {
      category: 'آموزش های سازمانی',
      title: 'رفتار سازمانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/intract.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'با آموزش‌های گروه مشاوره دکتر رمضانی، به سازمان خود کمک کنید تا محیطی پویا و الهام‌بخش بسازد. ارتقاء فرهنگ سازمانی شما می‌تواند به بهبود عملکرد، انگیزش و رضایت کارکنان منجر شود.'
    },
    'آموزش ها سازمانی بازاریابی و فروش': {
      category: 'آموزش های سازمانی',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/sales.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'فرصتی طلایی برای ارتقاء مهارت‌های بازاریابی و فروش خود! با آموزش‌، ابزارهای لازم برای جذب مشتریان بیشتر و بستن قراردادهای موفق را در اختیار خواهید داشت.'
    },
    'آموزش ها سازمانی مدیریت استراتژیک': {
      category: 'آموزش های سازمانی',
      title: 'مدیریت استراتژیک',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/research.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'با آموزش‌های مدیریت استراتژیک، ابزارهای لازم برای تحلیل دقیق بازار، شناسایی فرصت‌ها و تدوین استراتژی‌های کلیدی را به دست آورید و سازمان خود را در مسیر رقابتی و موفق هدایت کنید.'
    },
    'آموزش ها سازمانی تبلیغات': {
      category: 'آموزش های سازمانی',
      title: 'تبلیغات',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/ad.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'به دنیای تبلیغات حرفه‌ای وارد شوید! با دوره‌های ما، راهکارهای عملی برای بهینه‌سازی تبلیغات و دستیابی به نتایج فوق‌العاده را یاد بگیرید و کسب‌وکار خود را به سطح جدیدی از موفقیت برسانید.'
    },
    'آموزش ها سازمانی مدیریت منابع انسانی': {
      category: 'آموزش های سازمانی',
      title: 'مدیریت منابع انسانی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/hr.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'آینده موفق سازمان شما با مدیریت منابع انسانی کارآمد آغاز می‌شود! دوره‌های تخصصی ما به شما ابزارها و تکنیک‌های ضروری برای ایجاد یک محیط کاری مثبت و افزایش اثربخشی تیم را ارائه می‌دهند.'
    },
    'آموزش ها سازمانی ارزیابی عملکرد': {
      category: 'آموزش های سازمانی',
      title: 'ارزیابی عملکرد',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/investigate.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'تحلیل دقیق عملکرد نیروها برای شناسایی فرصت‌های بهبود و ارتقاء کارایی سازمانی. به ما اعتماد کنید تا نتایج بهتری بگیرید.'
    },
    'آموزش ها سازمانی فروشنده حرفه‌ای': {
      category: 'آموزش های سازمانی',
      title: 'فروشنده حرفه‌ای',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqOrganizationalTrainings.personal,
      imagePath: './assets/img/seller.svg',
      chartPath: './assets/charts/organizationalTrainings.png',
      text: 'با آموزش‌های ما در فروشندگی حرفه‌ای، استراتژی‌های اثبات‌شده و تکنیک‌های برتر را یاد بگیرید که به شما کمک می‌کنند تا نیازهای مشتریان را به‌طور مؤثر شناسایی کرده و به فروش‌های بیشتر دست پیدا کنید.'
    },
    //
    'بازاریابی و فروش': {
      category: 'بازاریابی و فروش',
      title: 'بازاریابی و فروش',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/team.png',
      text: 'راهکارهای استراتژیک و عملی برای فروش محصولات سازمان، به افزایش درآمد و توسعه بازار کمک می‌کنیم. تحلیل بازار، تدوین استراتژی‌های فروش، و اجرای کمپین‌های بازاریابی که به جذب مشتریان جدید و حفظ مشتریان فعلی کمک می‌کند. درک عمیق از نیازهای بازار و رفتار مشتریان، به شما کمک می‌کند تا به هدف‌های فروش خود برسید و سهم بازار خود را افزایش دهید. این فرآیند می تواند با ایجاد تیم فروش قوی در سازمان و یا برون سپاری فروش محصولات به مجموعه ای متخصص انجام شود و هزینه‌های عملیاتی شما کاهش یابد. ارائه خدمات فروش حرفه‌ای، از جمله مدیریت روابط با مشتریان، توسعه بازار، و پیگیری فروش، به شما کمک می‌کند تا عملکرد فروش خود را بهینه کنید و به رشد و موفقیت بیشتر دست یابید.'
    },
    'بازاریابی و فروش تیم سازی فروش (درون سازمانی)': {
      category: 'بازاریابی و فروش',
      title: 'تیم سازی فروش (درون سازمانی)',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/team.svg',
      chartPath: './assets/charts/team.png',
      text: 'تیم فروش قوی، کلید موفقیت پایدار است. به شما کمک می کنیم تا با انتخاب افراد و تیم سازی فروش درون سازمان خود، آینده کسب‌وکار خود را بسازید!'
    },
    'بازاریابی و فروش فروش (برون سپاری)': {
      category: 'بازاریابی و فروش',
      title: 'فروش (برون سپاری)',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqMarketing.personal,
      imagePath: './assets/img/marketing.svg',
      chartPath: './assets/charts/marketing.png',
      text: 'مدیریت فروش خود را به متخصصان بسپارید و نتایج بهتری بگیرید. با برون سپاری فروش سازمان خود می توانید ارتقا فرو و سودآوری بیشتر را تجربه کنید"    فروش، تخصص ماست.'
    },
    //
    'استعلام و ارزیابی': {
      category: 'استعلام و ارزیابی',
      title: 'استعلام و ارزیابی',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/service.svg',
      chartPath: './assets/charts/inquery.png',
      text: 'نیازهای واقعی و اولویت‌های خود را به‌طور دقیق شناسایی کنید. خدمات استعلام و تأمین شامل تحقیق و ارزیابی تأمین‌کنندگان و منابع مختلف برای تأمین کالاها و خدمات مورد نیاز سازمان شماست. با بهره‌گیری از شبکه گسترده تأمین‌کنندگان و تحلیل بازار، بهترین گزینه‌ها را از نظر کیفیت، قیمت و شرایط تأمین به شما معرفی می‌کنیم. این خدمات به سازمان شما کمک می‌کند تا با کمترین هزینه و بالاترین کیفیت، نیازهای خود را به‌طور مؤثر تأمین کرده و فرآیند خرید و تأمین را بهینه کنید.'
    },
    'استعلام و ارزیابی استعلام و ارائه خدمات': {
      category: 'استعلام و ارزیابی',
      title: 'تامین کالا و خدمات به صورت ویژه',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/service.svg',
      chartPath: './assets/charts/inquery.png',
      text: 'استعلام سریع و دقیق برای تأمین کالا و خدمات، به ساده‌ترین شکل ممکن. از مشاوره‌های ما بهره‌مند شوید!'
    },
    'استعلام و ارزیابی ارزیابی تامین کننده': {
      category: 'استعلام و ارزیابی',
      title: 'استعلام تامین کننده',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqInquery.personal,
      imagePath: './assets/img/filter.svg',
      chartPath: './assets/charts/inquery.png',
      text: 'یافتن تأمین‌کننده مناسب، کلید موفقیت کسب‌وکار شماست. ما در انتخاب و ارزیابی به شما کمک می‌کنیم!'
    },
    //
    'سرمایه گذاری': {
      category: 'سرمایه گذاری',
      title: 'سرمایه گذاری',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/money-2.svg',
      chartPath: './assets/charts/founder.png',
      text: 'بخش مهمی از هر کسب و کار، موضوع مالی و تامین مالی و هزینه هاست. بسیاری از کسب و کارها در صورت تامین مالی می توانند محصولات بسیار کارآمد و مفید و مناسب بازار تولید کنند. در این راستا تامین سرمایه گذار مناسب، یکی از راه های بسیار موثر در رشد کسب و کار است. همچنین افرادی که سرمایه دار هستند، نیاز به مکانی امن برای سرمایه گذاری هستند. سامانه جامع مشاوره کسب و کار فضایی است که کمک می کند تا شرکت ها به سرمایه گذاری مناسب دست یابند و سرمایه گذار نیز به آسودگی سرمایه گذاری امن و مطمعن و سودآوری داشته باشند.'
    },
    'سرمایه گذاری جذب سرمایه گذار': {
      category: 'سرمایه گذاری',
      title: 'جذب سرمایه گذار',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/money-2.svg',
      chartPath: './assets/charts/founder.png',
      text: 'سرمایه‌گذاران مناسب می‌توانند پروژه شما را به اوج برسانند. ما راه‌حل‌های مؤثری برای جذب آن‌ها ارائه می‌دهیم!'
    },
    'سرمایه گذاری سرمایه گذاری': {
      category: 'سرمایه گذاری',
      title: 'سرمایه گذاری',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqFounder.personal,
      imagePath: './assets/img/investing.svg',
      chartPath: './assets/charts/founder.png',
      text: 'به دنبال سرمایه‌گذاری موفق و مطمئن هستید؟ مشاوره ما شما را در مسیر درست هدایت می‌کند!'
    },
    //
    'ایده تا محصول ایده تا محصول': {
      category: 'ایده تا محصول',
      title: 'ایده تا محصول',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqIdea.personal,
      imagePath: './assets/img/idea.svg',
      chartPath: './assets/charts/idea.png',
      text: 'بسیاری از افراد، ایده های خلاق و نوآورانه ای دارند اما در مسیر تبدیل این ایده به محصول قابل فروش، با مشکلات فراوان از جمله عدم آگاهی از مسایل قانونی، شناخت بازار، نیاز مصرف کننده، شناخت رقبا، مسایل مالی، و غیره مواجه می شوند و امکان تجاری سازی ایده خود را از دست می دهند. گروه مشاوره دکتر رمضانی به شما کمک خواهد کرد تا ایده خود را به محصول قابل ارائه و تجاری سازی ایده خود بپردازید.'
    },
    'ایده تا محصول': {
      category: 'ایده تا محصول',
      title: 'ایده تا محصول',
      subtitle: '',
      description: '',
      url: ROUTES.roles.reqIdea.personal,
      imagePath: './assets/img/idea.svg',
      chartPath: './assets/charts/idea.png',
      text: 'بسیاری از افراد، ایده های خلاق و نوآورانه ای دارند اما در مسیر تبدیل این ایده به محصول قابل فروش، با مشکلات فراوان از جمله عدم آگاهی از مسایل قانونی، شناخت بازار، نیاز مصرف کننده، شناخت رقبا، مسایل مالی، و غیره مواجه می شوند و امکان تجاری سازی ایده خود را از دست می دهند. گروه مشاوره دکتر رمضانی به شما کمک خواهد کرد تا ایده خود را به محصول قابل ارائه و تجاری سازی ایده خود بپردازید.'
    },

    '-': {
      title: 'سامانه جامه مشاوره کسب و کار',
      subtitle: '',
      description: '',
      url: '/',
      imagePath: '-',
      chartPath: '',
      text: 'آینده کسب‌وکارتان را با خدمات مشاوره حرفه‌ای در سامانه جامع مشاوره کسب‌و‌کار تضمین کنید. با تخصص و تجربه تیم گروه مشاوره دکتر رمضانی، به بهره‌وری بیشتر و هزینه‌های کمتر دست یابید و موفقیت را تجربه کنید.'
    }
  });
  const { service } = useParams();
  const { user } = useUser();
  return (
    <section className="container mx-auto">
      <FlexboxGrid className="!items-center mt-5  min-h-[60vh]">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={12}
          className="flex flex-column items-center">
          <h1 className="heading-text">{pages[service ?? '-']?.title}</h1>
          <h3 className="heading-text">{pages[service ?? '-']?.subtitle}</h3>
          <p className="sub-heading-text">
            {pages[service ?? '-']?.text ||
              'آینده کسب‌وکارتان را با خدمات مشاوره حرفه‌ای در سامانه جامع مشاوره کسب‌و‌کار تضمین کنید. با تخصص و تجربه تیم گروه مشاوره دکتر رمضانی، به بهره‌وری بیشتر و هزینه‌های کمتر دست یابید و موفقیت را تجربه کنید.'}
          </p>
          <div className="flex items-center md:justify-start justify-center flex-row">
            <Button
              appearance="primary"
              size="lg"
              className="mt-5 w-fit"
              onClick={() => {
                if (user.isLoggedIn) {
                  navigate(`${pages[service ?? '-']?.url}`);
                } else {
                  navigate(`/auth/login?redirect=${pages[service ?? '-']?.url}`);
                }
              }}>
              شروع کنید
            </Button>
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={12}
          className="flex flex-column items-center justify-center">
          <img src={pages[service ?? '-']?.imagePath} alt="" className="w-[80%] mx-auto" />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid className="!items-center mt-5">
        <FlexboxGrid.Item
          as={Col}
          colspan={24}
          sm={24}
          md={24}
          className="flex flex-column items-center justify-center bg-white border-[1px] rounded-md border-[--rs-primary-300] p-4 mt-6">
          <img
            src={pages[service ?? '-']?.chartPath}
            alt=""
            className="w-[100%] md:w-[85%] lg:w-[75%] mx-auto"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </section>
  );
};
