/* eslint-disable @typescript-eslint/no-explicit-any */
import { faIR } from 'date-fns-jalali/locale';
import { FormatDateToBeautifulYear } from '../../../../utils/helpers';
import { Divider } from 'rsuite';

export const ConsultantWorkBackgroundView = ({ data }: any) => {
  return (
    <>
      {data?.length ? (
        <div className="w-full">
          <Divider className="text-[20px] font-bold">سوابق شغلی</Divider>
        </div>
      ) : null}
      <ul style={{ paddingRight: '12px' }}>
        {data?.map((work: any) => {
          return (
            <li key={work.id} className="mb-4">
              <div className="text-[16px] font-bold flex flex-col">
                <div className="flex flex-row justify-between px-4 py-3 bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md mb-2">
                  <div>
                    {work.Role} {work.Name ? ` - ${work.Name}` : ''}
                  </div>
                  <div className="flex gap-4">
                    <div>شروع از {FormatDateToBeautifulYear(work.Start_Date, faIR)}</div>
                    <div>{work.CurrentWork ? 'مشغول به کار' : work.Duration}</div>
                    <div>
                      {work.Country == 'ایران'
                        ? `${work.State} - ${work.City}`
                        : `${work.Country} - ${work.City}`}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
