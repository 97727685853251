import {
  PostCorporationGoodModel,
  PostCorporationIntroModel,
  PostCorporationPersonalModel,
  PostCorporationServiceModel
} from '../models';
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const postCorporationPersonal = (payload: PostCorporationPersonalModel) =>
  appNetworkManager.post(`/corporationpersonal`, { ...payload, Username: getUsername() });

export const getCorporationPersonal = (username: string | null | undefined) => {
  return appNetworkManager.get(`/corporationpersonal?Username=${username ?? getUsername()}`);
};

export const postCorporationIntro = (payload: PostCorporationIntroModel) =>
  appNetworkManager.post(`/corporationintro`, { ...payload, Username: getUsername() });

export const getCorporationIntro = (username: string | null | undefined) => {
  return appNetworkManager.get(`/corporationintro?Username=${username ?? getUsername()}`);
};

// good
export const postCorporationGood = (payload: PostCorporationGoodModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/corporationgood`, { ...rest, Username: getUsername() });
};

export const getCorporationGood = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/corporationgood?Username=${username ?? getUsername()}`);
};
export const deleteCorporationGood = (id: number | string) => {
  return appNetworkManager.delete(`/corporationgood?ID=${id}`);
};

// service
export const postCorporationService = (payload: PostCorporationServiceModel) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/corporationservice`, { ...rest, Username: getUsername() });
};

export const getCorporationService = (username: string | null | undefined) => {
  // eslint-disable-next-line no-unused-vars
  return appNetworkManager.get(`/corporationservice?Username=${username ?? getUsername()}`);
};
export const deleteCorporationService = (id: number | string) => {
  return appNetworkManager.delete(`/corporationservice?ID=${id}`);
};

// register request
export const postCorporationRegisterRequest = () => {
  return appNetworkManager.post(`/corporationregreq`, {
    Username: getUsername(),
    Status: 'Pending'
  });
};
export const putCorporationRegisterRequest = (payload: { Status: String }) => {
  return appNetworkManager.put(`/corporationregreq`, { ...payload, Username: getUsername() });
};

export const getCorporationRegisterRequest = () => {
  return appNetworkManager.get(`/corporationregreq?Username=${getUsername()}`);
};
export const getCorporations = () => {
  return appNetworkManager.get(`/corporations`);
};
export const getCorporationsWithProduct = (_search: string) => {
  return appNetworkManager.post(`/corporationsearch`, { Services: _search, Goods: _search });
};
export const postCorporationBuycv = (seekerUsername: string) => {
  return appNetworkManager.get(
    `/buycv?EmployerUsername=${getUsername()}&JobSeekerUsername=${seekerUsername}`
  );
};
export const postRequestBuyCv = (seekerUsername: string) => {
  return appNetworkManager.post(`/reqcv`, {
    EmployerUsername: getUsername(),
    JobSeekerUsername: seekerUsername
  });
};
export const getCorporationBuycv = (jobSeekerUsername: string) => {
  return appNetworkManager.get(
    `/readcv?EmployerUsername=${getUsername()}&JobSeekerUsername=${jobSeekerUsername}`
  );
};
