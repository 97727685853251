import { Schema } from 'rsuite';

const { StringType } = Schema.Types;

export interface CorporationFormValueModel {
  FullName: String;
  PostalCode: String;
  Address: String;
  RegistrationNumber: String;
  NID: String;
  TypeLegal: String;
  // Country: String;
  State: String;
  City: String;
  CEOFirstName: String;
  CEOLastName: String;
  CEONID: String;
  CEORID?: String;
  CEOFathersName?: String;
  Email: String;
  CellPhone: String;
  FixNumber: String;
  Website: String;
  CEOFieldOfStudy: String;
  CEOEducationOfThe: String;
  CEODirection: String;
  PICGLedger: String;
  PICLastChanged: String;
  PICNID: String;
}

export interface PostCorporationPersonalModel extends CorporationFormValueModel {
  Sex: String;
  Username?: String;
  DateOfEstablishment: String;
  CEOBirthDate: String;
}

export const CorporationPersonalFormSchema = Schema.Model({
  FullName: StringType('').isRequired('الزامی است!'),
  CEOFirstName: StringType('').isRequired('الزامی است!'),
  CEOLastName: StringType('').isRequired('الزامی است!'),
  NID: StringType('').isRequired('الزامی است!'),
  TypeLegal: StringType('').isRequired('الزامی است!'),
  CEONID: StringType('').isRequired('الزامی است!'),
  // CEORID: StringType('').isRequired('الزامی است!'),
  // Sex: StringType('').isRequired('الزامی است!'),
  // CEOFathersName: StringType('').isRequired('الزامی است!'),
  Email: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'فرمت وارد شده اشتباه است!'),
  // CountryB: StringType('').isRequired('الزامی است!'),
  State: StringType(''),
  City: StringType('').isRequired('الزامی است!'),
  CellPhone: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^0[0-9]{10}$/, 'شماره تلفن را با صفر و بدون کد کشور وارد کنید!'),
  FixNumber: StringType('').isRequired('الزامی است!'),
  Website: StringType('').pattern(
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
    'فرمت وبسایت وارد شده اشتباه است! مثال https://cbcs.ir'
  ),
  CEOFieldOfStudy: StringType('').isRequired('الزامی است!'),
  CEOEducationOfThe: StringType('').isRequired('الزامی است!'),
  CEODirection: StringType(''),
  PostalCode: StringType('').isRequired('الزامی است!'),
  Address: StringType('').isRequired('الزامی است!'),
  RegistrationNumber: StringType('').isRequired('الزامی است!'),
  PICGLedger: StringType(''),
  PICLastChanged: StringType(''),
  PICNID: StringType('')
});

// intro

export interface CorporationIntroFormValueModel {
  History: String;
  Value: String;
  Sight: String;
  Activity: String;
  MajorEconomic: String;
  Category: String;
  PICLogo?: String;
  IntroVideo?: String;
}

export interface PostCorporationIntroModel extends CorporationIntroFormValueModel {
  // Sex: String;
  // Username?: String;
  // DateOfEstablishment: String;
  // CEOBirthDate: String;
}

export const CorporationIntroFormSchema = Schema.Model({
  History: StringType(''),
  Value: StringType(''),
  Sight: StringType(''),
  Activity: StringType('').isRequired('الزامی است!'),
  MajorEconomic: StringType('').isRequired('الزامی است!'),
  Category: StringType('').isRequired('الزامی است!'),
  PICLogo: StringType(''),
  IntroVideo: StringType('')
});

// good
export interface CorporationGoodFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
  Produced: Boolean;
  PICs: string;
}
export const defaultCorporationGoodFormValue = {
  Name: '',
  Description: '',
  Produced: '',
  PICs: '',
  File: ''
};

export const defaultCorporationGoodSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
  // PICs: StringType('').isRequired('الزامی است!')
});

export interface PostCorporationGoodModel extends CorporationGoodFormValueModel {
  // StudyNow: boolean;
  // Country: String;
  // Start_Date: String;
  // End_Date?: String;
}

// service
export interface CorporationServiceFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  Description: String;
}
export const defaultCorporationServiceFormValue = {
  Name: '',
  Description: ''
};

export const defaultCorporationServiceSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  Description: StringType('').isRequired('الزامی است!')
});

export interface PostCorporationServiceModel extends CorporationServiceFormValueModel {}
