/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUsername } from './config';
import { authNetworkManager, appNetworkManager } from './network-manager';

export const putReqLoginSms = (username: string) =>
  authNetworkManager.put(`/loginws`, { Username: username });
export const postLoginWithSms = (username: string, sMSCode: string) =>
  authNetworkManager.post(`/loginws`, { Username: username, SMSCode: sMSCode });

export const postLoginWithPassword = (username: string, password: string) =>
  authNetworkManager.post(`/loginwp`, { CellPhone: username, Password: password });

export const postLoginWithPassword2 = (username: string, password: string) =>
  appNetworkManager.post(`/loginwp`, { Username: username, Password: password });

export const getUserProfile = (username: string, isCellPhone = false) =>
  appNetworkManager.get(`/users?${isCellPhone ? 'CellPhone' : 'Username'}=${username}`);
export const getBoughtResumes = () =>
  appNetworkManager.get(`/readcv?EmployerUsername=${getUsername()}`);
export const getRequestedResumesByEmployeeUsername = () =>
  appNetworkManager.get(`/reqcv?EPUsername=${getUsername()}`);
export const getRequestedResumesBySeekerUsername = () =>
  appNetworkManager.get(`/reqcv?JSUsername=${getUsername()}`);

export const postRequestedResume = (payload: any) => appNetworkManager.post(`/reqcv`, payload);
export const putRequestedResume = (id: any, status: any) =>
  appNetworkManager.put(`/updatereqcv`, { ID: id, Status: status });

export const getUserRoles = (username: string) => authNetworkManager.get(`/user/role/${username}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postChangePassword = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.post(`/resetpassword`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putReqChangePassword = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.put(`/resetpassword`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSignupUser = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return authNetworkManager.post(`/users`, rest);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSignupUser2 = (payload: any) => {
  // eslint-disable-next-line no-unused-vars
  const { ...rest } = payload;
  return appNetworkManager.post(`/users`, rest);
};

export const getUser = (username: string, isCellPhone = false) =>
  authNetworkManager.get(`/users?${isCellPhone ? 'CellPhone' : 'Username'}=${username}`);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAddRoleToUser = (role: string, username: string) => {
  // eslint-disable-next-line no-unused-vars
  return authNetworkManager.post(`/user/role/${username}?Role=${role}`, {});
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteRoleFromUser = (role: string, username: string) => {
  // eslint-disable-next-line no-unused-vars
  return authNetworkManager.delete(`/user/role/${username}?Role=${role}`, {});
};
