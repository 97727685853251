/* eslint-disable @typescript-eslint/no-explicit-any */

import { Divider, Rate } from 'rsuite';

const SKILL_MAPPER: any = {
  مبتدی: 1,
  'پایین تر از متوسط': 2,
  متوسط: 3,
  'بالا تر از متوسط': 4,
  پیشرفته: 5
};
export const SeekerSkillBackgroundView = ({ data }: any) => {
  return (
    <>
      {/* <h4 className="mb-4">مهارت ها</h4> */}
      {data?.length ? (
        <div className="w-full">
          <Divider className="text-[20px] font-bold">مهارت ها</Divider>
        </div>
      ) : null}
      <ol style={{ listStyle: 'auto', paddingRight: '12px' }}>
        {data?.map((skill: any) => {
          return (
            <li key={skill.id} className="mb-4 text-[16px] flex items-center font-bold">
              <div className="w-[120px]">{skill.Skills}</div>{' '}
              <Rate value={SKILL_MAPPER[skill.Level]} readOnly color="yellow" size="sm" />
            </li>
          );
        })}
      </ol>
    </>
  );
};
