/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getUser,
  getUserProfile,
  getUserRoles,
  postChangePassword,
  postLoginWithPassword,
  postLoginWithSms,
  postSignupUser,
  postSignupUser2,
  putReqChangePassword,
  putReqLoginSms,
  getBoughtResumes,
  postRequestedResume,
  getRequestedResumesByEmployeeUsername,
  getRequestedResumesBySeekerUsername,
  putRequestedResume
} from '../core';
import { showToast } from '../utils/toast';

export const handleGetBoughtResumes = async () => {
  try {
    const { data, status } = await getBoughtResumes();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      showToast('رزومه ای موجود نیست!', 'error');
      return { ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetRequestedResumesByEmployeeUsername = async () => {
  try {
    const { data, status } = await getRequestedResumesByEmployeeUsername();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      showToast('رزومه ای موجود نیست!', 'error');
      return { ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleGetRequestedResumesBySeekerUsername = async () => {
  try {
    const { data, status } = await getRequestedResumesBySeekerUsername();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      showToast('درخواستی موجود نیست!', 'error');
      return { ok: false };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePostRequestedResume = async (payload: any) => {
  try {
    const { data, status } = await postRequestedResume(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutRequestedResume = async (id: any, status1: string) => {
  try {
    const { data, status } = await putRequestedResume(id, status1);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetUserProfile = async (username: string, isCellPhone = false) => {
  try {
    const { data, status } = await getUserProfile(username, isCellPhone);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetUserRoles = async (username: string) => {
  try {
    const { data, status } = await getUserRoles(username);
    if (status === 200) {
      console.log(data.Data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { data: data.Data.map((item: any) => item.Role), ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleReqLoginSms = async (username: string) => {
  try {
    const { data, status } = await putReqLoginSms(username);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handleLoginWithSms = async (username: string, smsCode: string) => {
  try {
    const { data, status } = await postLoginWithSms(username, smsCode);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    // showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
    showToast('کد وارد شده مطابقت ندارد!', 'error');
  }
  return { ok: false };
};
export const handleLoginWithPassword = async (username: string, password: string) => {
  try {
    const { data, status } = await postLoginWithPassword(username, password);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    // showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
    showToast('رمزعبور مطابقت ندارد!', 'error');
  }
  return { ok: false };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleChangePassword = async (payload: any) => {
  try {
    const { data, status } = await postChangePassword(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleReqChangePassword = async (payload: any) => {
  try {
    const { data, status } = await putReqChangePassword(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleSignupUser = async (payload: any) => {
  try {
    const { data, status } = await postSignupUser(payload);
    const username = data.Data.Data.Username; //todo check it
    const res1 = await postSignupUser2({ ...payload, Username: username });
    if (status === 200 && res1.status === 200) {
      const res = await handleLoginWithPassword(data.Data.Data.CellPhone, payload.Password);
      if (res.ok) {
        return res;
      }
      return { data: data.Data, ok: true, notLoggedIn: true };
    } else {
      showToast(data.Data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleIsUserExists = async (username: string, isCellPhone = false) => {
  try {
    const { data, status } = await getUser(username, isCellPhone);
    if (status === 200) {
      return { data: data.Data ? true : false, ok: true };
    } else {
      showToast(data.Data.Message, 'error');
    }
    console.log('NOT 200', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { data: false, ok: true };
    }
    console.log(e.response?.data.Message?.FA);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
