// import { getUsername } from './config';
import { ConsultReportByConsultant, ConsultReportByUser, ConsultRequest } from '../models/consult';
import { appNetworkManager } from './network-manager';

export const postConsultReportByConsultant = (payload: ConsultReportByConsultant) => {
  return appNetworkManager.post(`/consrepcons`, { ...payload });
};
export const postConsultReportByUser = (payload: ConsultReportByUser) => {
  return appNetworkManager.post(`/consrepuser`, { ...payload });
};
export const getConsultReportByConsultant = (id: number) => {
  return appNetworkManager.get(`/consrepcons?ConsultID=${id}`);
};
export const getConsultReportByUser = (id: number) => {
  return appNetworkManager.get(`/consrepuser?ConsultID=${id}`);
};
export const postConsultRequest = (payload: ConsultRequest) => {
  return appNetworkManager.post(`/conssessions`, { ...payload });
};
export const getConsultRequests = () => {
  // todo set username or consultantusernem
  return appNetworkManager.post(`/conssessions`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSessionTimeByConsultant = (id: number, sessionTimes: any) => {
  return appNetworkManager.patch(`/conssession?ID=${id}`, { TimeTable: sessionTimes });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putSessionTimeByUser = (id: number, sessionTimes: any, selectedTime: any) => {
  return appNetworkManager.put(`/conssession?ID=${id}`, {
    TimeTable: sessionTimes,
    SessionTimeStamp: selectedTime
  });
};

// eslint-disable-next-lin4e @typescript-eslint/no-explicit-any
export const putSessionTimeByConsultant = (id: number, address: string) => {
  return appNetworkManager.put(`/conssessions?ID=${id}`, {
    Location: address
  });
};
export const patchSessionDoneByConsultant = (id: number) => {
  return appNetworkManager.patch(`/conssessions?ID=${id}`, {});
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const patchSessionEndedByConsultant = (id: number, _address: string) => {
//   return appNetworkManager.patch(`/conssessions?ID=${id}`, {});
// };

export const getSession = (id: number) => {
  return appNetworkManager.get(`/conssession?ID=${id}`);
};

export const getConsultRequestsOfAConsultant = (username: string) => {
  return appNetworkManager.get(`/conssessions?Consultant=${username}`);
};

export const getConsultRequestsOfAUser = (username: string) => {
  return appNetworkManager.get(`/conssessions?Username=${username}`);
};

export const getSessions = (timestamp: string) => {
  const date = new Date(timestamp);
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  return appNetworkManager.get(`/checkmeet?DateTime=${dateString}`);
};
