/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUsername } from './config';
import { appNetworkManager } from './network-manager';

export const getTicketById = (id: any) => appNetworkManager.get(`/ticket?ID=${id}`);

export const getTicketMessages = (id: any) =>
  appNetworkManager.get(`/ticketmessage?TicketID=${id}`);

export const getCurrentUserTickets = () =>
  appNetworkManager.get(`/ticket?Username=${getUsername()}`);

export const getAdminTickets = () => appNetworkManager.get(`/tickets`);

export const createTicket = (payload: any) =>
  appNetworkManager.post(`/ticket`, { ...payload, Username: getUsername() });

export const createTicketMessage = (payload: any) =>
  appNetworkManager.post(`/ticketmessage`, { ...payload, Username: getUsername() });

export const putTicket = (status: any, id: any) =>
  appNetworkManager.put(`/updateticket`, { Status: status, ID: id });
