import { Button, Form, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import IconTextField from '../../../../components/form/icon-textfield';
// import { Hide, Show } from 'react-iconly';
import { handleLoginWithSms, handleReqLoginSms } from '../../../../api-handler';
import { useUser } from '../../../../contexts/user';
import { showToast } from '../../../../utils/toast';
import Field from './../../../../components/form/field';
import OtpInput from 'react-otp-input';

const { StringType } = Schema.Types;
const model = Schema.Model({
  SMSCode: StringType('').isRequired('رمز عبور را وارد کنید.')
});
const DEFAULT_SECONDS = 120;

export const LoginOtp = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStep: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  step: any;
}) => {
  const [searchParams] = useSearchParams();
  const [redirect] = useState(searchParams.get('redirect'));
  const { setToken, setUserTrigger, userTrigger } = useUser();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const [seconds, setSeconds] = useState(DEFAULT_SECONDS);
  const [triggerCounter, setTriggerCounter] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    setLoading(true);
    const res = await handleLoginWithSms(props.step.data.Username, otp);
    if (res.ok) {
      // set token and user data
      // todo: start here
      setToken(res.data.Data.Token, res.data.Data.Username, 'no');
      setUserTrigger(!userTrigger);
      navigate(redirect ?? '/');
    }
    setLoading(false);
  }, [formValue, formError, formRef, props, redirect, otp]);

  const sendAgain = async () => {
    setLoading(true);
    const res = await handleReqLoginSms(props.step.data.Username);
    if (res.ok) {
      showToast('کد یکبار مصرف با موفقیت ارسال شد.', 'success');
      setTriggerCounter(!triggerCounter);
    } else {
      console.log(res);
    }
    setLoading(false);
  };
  //
  useEffect(() => {
    setSeconds(DEFAULT_SECONDS);
    let secondds = DEFAULT_SECONDS;
    const timer = setInterval(() => {
      setSeconds((prev) => prev - 1);
      secondds--;
      if (secondds <= 0) {
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [props.step.data.Username, triggerCounter]);
  useEffect(() => {
    sendAgain();
    console.log('rrrrrrrrrrrrrrrrrrrr');
  }, [props.step.data.Username]);

  // const [visible, setVisible] = useState(false);
  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      formError={formError}
      model={model}
      onSubmit={handleSubmit}
      checkTrigger="change"
      readOnly={loading}
      className="mt-8 text-dark">
      {/* <IconTextField
        name="SMSCode"
        label="رمز عبور"
        type={visible ? 'text' : 'password'}
        accepter={Input}
        dir="ltr"
        onIconCLick={() => setVisible(!visible)}
        eyeVisible={visible}
        onIcon={<Show size="small" />}
        offIcon={<Hide size="small" />}
      /> */}
      <Field
        dir="rtl"
        accepter={OtpInput}
        value={otp}
        isInputNum={true}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any) => setOtp(value)}
        inputStyle="rs-input otp-input min-w-[2.5em] sm:min-w-[3em] me-1 ms-1 aspect-square"
        containerStyle={{ flexDirection: 'row-reverse' }}
        numInputs={6}
        separator={<span></span>}
        name="SMSCode"
        label="کد ارسال شده"
      />
      <span
        className="text-b3 text-[--rs-primary-500] mt-1 block cursor-pointer"
        onClick={() => {
          props.setStep({
            ...props.step,
            action: 'password'
          });
        }}>
        ورود با رمز عبور
      </span>
      <Button
        appearance={`primary`}
        type="button"
        className="w-[50%] mx-auto !block mt-4"
        onClick={sendAgain}
        loading={loading}
        disabled={seconds != 0}>
        {seconds
          ? `${seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60} : ${Math.floor(seconds / 60)}`
          : `ارسال مجدد کد`}
      </Button>
      <Button
        appearance="primary"
        type="submit"
        className="w-[50%] mx-auto !block mt-8"
        loading={loading}>
        ورود
      </Button>
    </Form>
  );
};
