import { Button, Col, Divider, FlexboxGrid } from 'rsuite';
import {
  FormatDateTime,
  MEETING_ADDRESSES,
  durationToHour,
  getArrayFilled
} from '../../utils/helpers';
import { useCallback, useEffect, useState } from 'react';
import { faIR } from 'date-fns-jalali/locale';
import { format, eachDayOfInterval } from 'date-fns-jalali';
import {
  handleGetSession,
  handleGetSessions,
  handlePatchSessionDoneByConsultant,
  handlePostSessionTimeByConsultant,
  handlePutSessionTimeByConsultant,
  handlePutSessionTimeByUser
} from '../../api-handler/consult';
import { useNavigate, useParams } from 'react-router-dom';
import { showToast } from '../../utils/toast';
import { CustomLoader } from '../../components/basic/loader';
import { useUser } from '../../contexts/user';

import { ReactComponent as FinishedImg } from '../../assets/icons/conssession/finished.svg';
import { ReactComponent as BookingImg } from '../../assets/icons/conssession/booking.svg';
import { ReactComponent as PayImg } from '../../assets/icons/conssession/pay.svg';
import { ReactComponent as CalendarImg } from '../../assets/icons/conssession/calendar.svg';
import { ReactComponent as WaitingImg } from '../../assets/icons/conssession/waiting.svg';
import { APP_BASE_URL } from '../../core';

export const TimeList = (props: {
  day: number;
  isTimeEnabled: (d: number, t: number, mode: 'hour' | 'half') => number;
  toggleSelectedTime: (
    d: number,
    t: number,
    e: number,
    mode: 'hour' | 'half',
    isSecondHalf: boolean
  ) => void;
  daysOfWeek: Date[];
  asConsultant: boolean;
  mode: 'hour' | 'half';
  viewMode: boolean;
}) => {
  return (
    <>
      <div className="flex flex-col w-[100%] xs:w-[50%] sm:w-[33%] md:w-[25%] lg:w-[14%] font-fd">
        <span className="text-center text-h5 mb-3">
          {format(props.daysOfWeek[props.day - 1].toString(), 'EEE dd MMMM', { locale: faIR })}
        </span>
        {/* <span className="text-center text-h5 mb-3">{weekdays[props.day - 1]}</span> */}
        {getArrayFilled(0, 24 - 8).map((timem8) => {
          const time = timem8 + 8;
          const isEnabled = props.isTimeEnabled(props.day, time, props.mode);
          return (
            <div className="w-full flex" key={`${props.day}-${time}`}>
              <Button
                className={`!mb-1 w-full mx-2 block ${isEnabled === 1 || isEnabled === 3 ? '!bg-[--rs-primary-500] !text-[--rs-gray-50]' : isEnabled === 2 || isEnabled === 4 ? '!bg-[--rs-success-500] !text-[--rs-gray-50]' : 'ffff'}`}
                onClick={() => {
                  if (props.viewMode) {
                    return;
                  } else {
                    props.toggleSelectedTime(props.day, time, isEnabled, props.mode, false); // means first half
                  }
                }}
                disabled={
                  !props.asConsultant && (isEnabled === 0 || isEnabled === 5 || isEnabled == 6)
                }>
                {time < 10 ? `0${time}` : time}:00
              </Button>
              {props.mode == 'half' ? (
                <Button
                  className={`!mb-1 w-full mx-2 block ${isEnabled === 1 || isEnabled === 5 ? '!bg-[--rs-primary-500] !text-[--rs-gray-50]' : isEnabled === 2 || isEnabled === 6 ? '!bg-[--rs-success-500] !text-[--rs-gray-50]' : 'ffff'}`}
                  onClick={() => {
                    if (props.viewMode) {
                      return;
                    } else {
                      props.toggleSelectedTime(props.day, time, isEnabled, props.mode, true); //2 means second half
                    }
                  }}
                  disabled={
                    !props.asConsultant && (isEnabled === 0 || isEnabled === 3 || isEnabled == 4)
                  }>
                  {time < 10 ? `0${time}` : time}:30
                </Button>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention
const SessionTitleAndImg = ({ title, Img, text }: { title: string; Img: any; text: string }) => {
  return (
    <div>
      <div>
        <h3 className="text-h3 text-center">{title}</h3>
      </div>
      <div className="flex items-center flex-col lg:flex-row  mt-6">
        <div className="flex justify-center w-[90%] lg:w-[50%]">
          <div className="w-full">
            <Img className="w-full" />
          </div>
        </div>
        <div className="mt-4 lg:mt-0 w-[90%] lg:w-[50%]">
          <p className="w-[100%] lg:w-[80%] mx-auto text-[18px]" style={{ textAlign: 'justify' }}>
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};
export const SetConsultSession = (props: { asConsultant: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>();
  const [mainLoading, setMainLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState(
    eachDayOfInterval({
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 6))
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedTimes, setSelectedTimes] = useState<any>({
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {}
  });
  const navigate = useNavigate();
  const { isUserRole } = useUser();
  const { id } = useParams();
  const [lastSelectedDay, setLastSelectedDay] = useState<number | null>(null);
  const [lastSelectedTime, setLastSelectedTime] = useState<number | null>(null);
  const [thatThing, setThatThing] = useState<number | null>(null);
  const [timeTableTimeStamp, setTimeTableTimeStamp] = useState(null);
  // const [address, setAddress] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedTime, setSelectedTime] = useState<any>();

  // 1 means 1 hour
  // 2 means set one hour

  // 3 means first half hour
  // 4 mens set first hour

  // 5 means second half hour
  // 6 means set second hour
  const isTimeEnabled = (d: number, t: number, _mode: 'hour' | 'half' = 'hour') => {
    const selectedTimesCopy = { ...selectedTimes };
    if (selectedTimesCopy[d][t] === 1) return 1;
    if (selectedTimesCopy[d][t] === 2) return 2;
    if (selectedTimesCopy[d][t] === 3) return 3;
    if (selectedTimesCopy[d][t] === 4) return 4;
    if (selectedTimesCopy[d][t] === 5) return 5;
    if (selectedTimesCopy[d][t] === 6) return 6;
    return 0;
  };

  const toggleSelectedTime = (
    d: number,
    t: number,
    isEnabled: number,
    mode: 'hour' | 'half',
    isSecondHalf: boolean
  ) => {
    const selectedTimesCopy = { ...selectedTimes };
    if (props.asConsultant) {
      console.log(isEnabled);
      if (isEnabled === 1 || isEnabled === 3 || isEnabled === 5) {
        if (mode === 'hour') {
          selectedTimesCopy[d][t] = 0;
        } else {
          if (!isSecondHalf && isEnabled === 3) {
            selectedTimesCopy[d][t] = 0;
          } else if (isSecondHalf && isEnabled === 3) {
            selectedTimesCopy[d][t] = 1;
          } else if (!isSecondHalf && isEnabled === 5) {
            selectedTimesCopy[d][t] = 1;
          } else if (isSecondHalf && isEnabled === 5) {
            selectedTimesCopy[d][t] = 0;
          } else if (!isSecondHalf && isEnabled === 1) {
            selectedTimesCopy[d][t] = 5;
          } else if (isSecondHalf && isEnabled === 1) {
            selectedTimesCopy[d][t] = 3;
          } else {
            selectedTimesCopy[d][t] = 0;
          }
        }
      } else {
        if (mode === 'hour') {
          selectedTimesCopy[d][t] = 1;
        } else {
          console.log('hmm', isSecondHalf, isEnabled);
          if (isSecondHalf) {
            if (isEnabled == 3) {
              selectedTimesCopy[d][t] = 1;
            } else {
              selectedTimesCopy[d][t] = 5;
            }
          } else {
            if (isEnabled == 5) {
              selectedTimesCopy[d][t] = 1;
            } else {
              selectedTimesCopy[d][t] = 3;
            }
          }
        }
      }
      setSelectedTimes(selectedTimesCopy);
    } else {
      if (isEnabled === 2) {
        //
      } else if (isEnabled === 1 || isEnabled === 3 || isEnabled === 5) {
        setLastSelectedDay(d);
        setLastSelectedTime(t);
        setThatThing(isEnabled);

        for (const outerKey in selectedTimesCopy) {
          for (const innerKey in selectedTimesCopy[outerKey]) {
            if (selectedTimesCopy[outerKey][innerKey] === 2) {
              selectedTimesCopy[outerKey][innerKey] = 1;
            } else if (selectedTimesCopy[outerKey][innerKey] === 4) {
              selectedTimesCopy[outerKey][innerKey] = 3;
            } else if (selectedTimesCopy[outerKey][innerKey] === 6) {
              selectedTimesCopy[outerKey][innerKey] = 5;
            }
          }
        }
        if (mode == 'hour') {
          selectedTimesCopy[d][t] = 2;
        } else {
          if (isSecondHalf) {
            selectedTimesCopy[d][t] = 6;
          } else {
            selectedTimesCopy[d][t] = 4;
          }
        }
        setSelectedTimes(selectedTimesCopy);
      }
    }
    return false;
  };

  const handleSetSessionTimeByConsultant = useCallback(async () => {
    setLoading(true);
    const res = await handlePostSessionTimeByConsultant(
      parseInt(id ?? '-1'),
      JSON.stringify(selectedTimes).replaceAll('"', "'")
    );
    if (res.ok) {
      showToast('ساعات با موفقیت ثبت شد', 'success');
      navigate('/consultant/requests');
    }
    setLoading(false);
  }, [selectedTimes, id]);

  const handleSetSessionAddressByConsultant = useCallback(
    async (addr: string) => {
      setLoading(true);
      // console.log(address);
      const res = await handlePutSessionTimeByConsultant(parseInt(id ?? '-1'), addr);
      if (res.ok) {
        // showToast('آدرس با موفقیت ثبت شد', 'success');
        navigate('/consultant/requests');
      }
      setLoading(false);
    },
    [id]
  );

  const isMeetAvailable = async (sessionTimeStamp: string) => {
    const res = await handleGetSessions(sessionTimeStamp);
    if (res.ok) {
      return res.data[0];
    }
    return null;
  };
  const handleSetSessionTimeByUserWithPayment = useCallback(async () => {
    setLoading(true);
    const ID = parseInt(id ?? '-1');
    const SessionTimeStamp = selectedTime.toISOString();
    const selectedMeetingLink = await isMeetAvailable(SessionTimeStamp);
    if (selectedMeetingLink) {
      const Location = data.Type === 'حضوری' ? MEETING_ADDRESSES.offline[0] : selectedMeetingLink;
      // buyconssession
      window.open(`
        ${APP_BASE_URL}/buyconssession?ID=${ID}&SessionTimeStamp=${SessionTimeStamp}&Location=${Location}`);
    } else {
      showToast('جلسه فعالی در ساعت انتخابی موجود نیست!', 'error');
    }

    setLoading(false);
  }, [selectedTime, id]);

  // eslint-disable-next-line no-unused-vars
  const handleSetSessionTimeByUser = useCallback(async () => {
    setLoading(true);
    const res = await handlePutSessionTimeByUser(
      parseInt(id ?? '-1'),
      JSON.stringify(selectedTimes).replaceAll('"', "'"),
      selectedTime
    );
    if (res.ok) {
      showToast('زمان انتخابی شما با موفقیت ثبت شد', 'success');
      if (data.Type === 'حضوری') {
        handleSetSessionAddressByConsultant(MEETING_ADDRESSES.offline[0]);
      } else {
        handleSetSessionAddressByConsultant(
          MEETING_ADDRESSES.online[Math.ceil(Math.random() * 20) - 1]
        );
      }
      navigate('/user/requests');
    }
    setLoading(false);
  }, [selectedTimes, id, selectedTime, data]);

  useEffect(() => {
    (async () => {
      setMainLoading(true);
      const res = await handleGetSession(parseInt(id ?? '-1'));
      if (res.ok) {
        setData(res.data);
        setMainLoading(false);
      }
      if (
        res.ok &&
        (res.data.Status === 'PendingUser' ||
          res.data.Status === 'PendingConsultant' ||
          res.data.Status === 'PendingMeeting')
      ) {
        setTimeTableTimeStamp(res.data.TimeTableTimeStamp);
        if (res.data.TimeTable) {
          setSelectedTimes(JSON.parse(res.data.TimeTable.replaceAll("'", '"')));
          setDaysOfWeek(
            eachDayOfInterval({
              start: new Date(res.data.TimeTableTimeStamp),
              end: new Date(new Date().setDate(new Date(res.data.TimeTableTimeStamp).getDate() + 6))
            })
          );
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    if (timeTableTimeStamp && lastSelectedTime) {
      const theDate = new Date(
        new Date().setDate(new Date(timeTableTimeStamp).getDate() + (lastSelectedDay ?? 0) + -1)
      );

      theDate.setHours(lastSelectedTime);
      theDate.setMinutes(thatThing == 5 ? 30 : 0);
      theDate.setSeconds(0);
      setSelectedTime(theDate);
      // console.log(format(theDate, 'dd MMMM yyyy hh:ss', { locale: faIR }));
      // console.log(
      //   parse(
      //     format(
      //       new Date(
      //         new Date().setDate(
      //           new Date(timeTableTimeStamp).getDate() + (lastSelectedDay ?? 0) + -1
      //         )
      //       ),
      //       'dd MMMM yyyy',
      //       { locale: faIR }
      //     ),
      //     'dd MMMM yyyy',
      //     new Date(),
      //     { locale: faIR }
      //   )
      // );
      // format(
      //   new Date(
      //     new Date().setDate(new Date(timeTableTimeStamp).getDate() + (lastSelectedDay ?? 0))
      //   ),
      //   'dd MMMM yyyy',
      //   { locale: faIR }
      // )
    }
  }, [timeTableTimeStamp, lastSelectedDay, lastSelectedTime, thatThing]);

  const handleConsultMeetingDone = useCallback(async () => {
    setLoading(true);
    const res = await handlePatchSessionDoneByConsultant(parseInt(id ?? '-1'));
    if (res.ok) {
      showToast('جلسه پایان یافت', 'success');
      navigate(`/consultant/meeting-report/${id}/${data.Consultant}/${data.Username}`);
    }
    setLoading(false);
  }, [id, data]);

  if (mainLoading) {
    return <CustomLoader />;
  }
  return (
    <>
      {data.Status === 'PendingUser' && !isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="لطفا از بین زمان های فعال زیر یک زمان برای وقت مشاوره انتخاب کنید"
          Img={BookingImg}
          text="در این بخش می توانید با انتخاب یکی از زمان های اعلام شده از سوی مشاور، نسبت به تایید و نهایی سازی جلسه مشاوره و پرداخت حق الزحمه مشاوره اقدام فرمایید.
          امیدواریم جلسه مشاوره بتواند راهگشای کسب و کار شما باشد.
          موفقیت شما، هدف اصلی ماست."
        />
      ) : null}
      {data.Status === 'PendingUser' && isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="در انتطار تایید و پرداخت کاربر، لطفا منتظر بمانید"
          Img={PayImg}
          text=""
        />
      ) : null}
      {data.Status === 'PendingConsultant' && !isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="لطفا صبر کنید تا مشاور زمان های خالی خود را وارد کند"
          text=""
          Img={CalendarImg}
        />
      ) : null}

      {data.Status === 'PendingConsultant' && isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="لطفا از بین زمان های زیر، زمان های خالی خود را انتخاب کنید"
          Img={CalendarImg}
          text="مشاور گرامی،
          لطفا از بین زمان های موجود، حداقل ۲ زمان که امکان برگزاری جلسه مشاوره را دارید انتخاب فرمایید تا با مشاوره گیرنده جهت برگزاری جلسه هماهنگ گردد.
          نکته: ترجیحا زمان های مشاوره را در روزهای غیر تعطیل و بین روزهای شنبه تا چهارشنبه از ساعت ۸ صبح الی ۴ عصر انتخاب فرمایید.
          سپاس از زحمات شما."
        />
      ) : null}

      {/* {data.Status === 'PendingMeeting' && isUserRole('Consultant') ? (
        <h4 className="text-h4">جزییات جلسه را وارد کنید</h4>
      ) : null}

      {data.Status === 'PendingMeeting' && isUserRole('User') ? (
        <h4 className="text-h4">جزییات بیشتر جلسه توسط مشاور قرار خواهد گرفت</h4>
      ) : null} */}

      {/* {data.Status === 'PendingDone' && isUserRole('Consultant') ? (
        <h4 className="text-h4">جزییات جلسه</h4>
      ) : null} */}
      {data.Status === 'PendingDone' && isUserRole('Consultant') ? (
        <SessionTitleAndImg title={`جزییات جلسه`} Img={WaitingImg} text="" />
      ) : null}
      {data.Status === 'PendingDone' && !isUserRole('Consultant') ? (
        <SessionTitleAndImg title={`جزییات جلسه`} Img={WaitingImg} text="" />
      ) : null}

      {data.Status === 'Done' && isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="جلسه پایان یافته"
          Img={FinishedImg}
          text="مشاور گرامی،
          ضمن قدردانی از زحمات ارزشمند شما، خواهشمند است با تکمیل فرم نظر سنجی در خصوص جلسه مشاوره انجام شده، ما را در بهبود و ارایه خدمات هر چه بهتر یاری رسانید.
          پیشاپیش از حسن همکاری شما سپاسگزاریم."
        />
      ) : null}
      {data.Status === 'Done' && !isUserRole('Consultant') ? (
        <SessionTitleAndImg
          title="جلسه پایان یافته"
          Img={FinishedImg}
          text="کارفرمای گرامی،
          امیدواریم جلسه مشاوره خوب و مفیدی برگزار شده باشد و راهکارهای مدیریت جهت کسب و کار خود دریافت کرده باشید.
          خواهشمند است با تکمیل فرم نظر سنجی در خصوص مشاوره انجام شده، ما را در بهبود و ارایه خدمات هر چه بهتر، یاری رسانید.
          پیشاپیش از حسن همکاری شما سپاسگزاریم."
        />
      ) : null}

      {data.Status === 'PendingUser' || data.Status === 'PendingConsultant' ? (
        <div className="flex justify-between mt-6" style={{ flexWrap: 'wrap' }}>
          {getArrayFilled(0, 7).map((daym1FromNow) => {
            const dayFromNow = daym1FromNow + 1;
            return (
              <TimeList
                key={dayFromNow}
                day={dayFromNow}
                isTimeEnabled={isTimeEnabled}
                toggleSelectedTime={toggleSelectedTime}
                mode={data.Duration == 1800000 ? 'half' : 'hour'} // hour, half
                viewMode={
                  (data.Status === 'PendingConsultant' && isUserRole('Consultant')) ||
                  (data.Status === 'PendingUser' && !isUserRole('Consultant'))
                    ? false
                    : true
                }
                daysOfWeek={daysOfWeek}
                asConsultant={props.asConsultant}
              />
            );
          })}
        </div>
      ) : null}

      {data && timeTableTimeStamp ? (
        <>
          {!lastSelectedTime ? null : (
            <>
              <Divider />
              <h4 className="text-center mt-4">
                {format(
                  new Date(
                    new Date().setDate(
                      new Date(timeTableTimeStamp).getDate() + (lastSelectedDay ?? 0) + -1
                    )
                  ),
                  'dd MMMM yyyy',
                  { locale: faIR }
                )}{' '}
                ساعت {lastSelectedTime}:{thatThing ? (thatThing == 5 ? '30' : '00') : '00'}
              </h4>
              <Button
                appearance="primary"
                size="lg"
                className="mx-auto mt-4 ml-2 !block"
                // onClick={handleSetSessionTimeByUser}
                onClick={handleSetSessionTimeByUserWithPayment}
                loading={loading}>
                ثبت و پرداخت
              </Button>
            </>
          )}
        </>
      ) : null}
      {data ? (
        <>
          {data.Status === 'PendingConsultant' && isUserRole('Consultant') ? (
            <Button
              appearance="primary"
              className="mr-auto mt-4 ml-2 !block"
              onClick={handleSetSessionTimeByConsultant}
              loading={loading}>
              ثبت ساعات آزاد
            </Button>
          ) : null}

          <FlexboxGrid className="bg-[--rs-primary-50] border-[1px] border-[--rs-primary-300] rounded-md p-3  mt-5">
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%] mb-4">
              <h5 className="text-h5 mb-3 ">اطلاعات جلسه مشاوره</h5>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] mb-4">
              <strong> عنوان:</strong> {data.Topic}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] mb-4">
              <strong> نوع مشاوره:</strong> {data.Type}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] mb-4">
              <strong> زمینه مشاوره: </strong> {data.Category}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] mb-4">
              <strong> مدت مشاوره :</strong> {durationToHour(data.Duration)}
            </FlexboxGrid.Item>
            {data.SessionTimeStamp ? (
              <FlexboxGrid.Item
                as={Col}
                colspan={24}
                sm={24}
                md={8}
                className="max-w-[100%] mb-4 font-fd">
                <strong> تاریخ و زمان :</strong> {FormatDateTime(data.SessionTimeStamp, faIR)}
              </FlexboxGrid.Item>
            ) : null}
            {data.Location ? (
              <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%] mb-4">
                <strong> آدرس :</strong> {data.Location}
              </FlexboxGrid.Item>
            ) : null}
          </FlexboxGrid>
        </>
      ) : null}

      {/* {data.Status === 'PendingMeeting' && isUserRole('Consultant') ? (
        <div className="mt-5">
          <br />
          آدرس جلسه اینترنتی یا حضوری را وارد کنید
          <Input value={address} onChange={setAddress} as="textarea" className="mt-1" />
          <Button
            appearance="primary"
            className="mr-auto mt-4 ml-2 !block"
            onClick={handleSetSessionAddressByConsultant}
            loading={loading}>
            افزودن آدرس
          </Button>
        </div>
      ) : null} */}

      {data.Status === 'PendingDone' && isUserRole('Consultant') ? (
        <Button
          appearance="primary"
          className="mr-auto mt-4 ml-2 !block"
          onClick={handleConsultMeetingDone}
          loading={loading}>
          جلسه پایان یافته؟
        </Button>
      ) : null}
    </>
  );
};
