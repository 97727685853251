/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/basic/loader';
import { TicketMessages } from './ticket-messages';
// eslint-disable-next-line no-unused-vars
import { handleGetTicketById, handlePostTicketMessage, handlePutTicket } from '../../api-handler';
import { Button, Input } from 'rsuite';
import { TICKET_CATEGORY_CONVERTOR, TICKET_STATUS_CONVERTOR } from '../../utils/helpers';

export const Ticket = (props: { isAdmin?: boolean }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [reload, setReload] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      //
      const res = await handleGetTicketById(id);
      if (res.ok) {
        setData(res.data);
        // start here
        if (props.isAdmin) {
          if (res.data.Status == 'PendingViewAdmin') {
            handlePutTicket('PendingAnswerAdmin', id);
          }
          if (res.data.Status == 'PendingViewUser') {
            handlePutTicket('PendingAnswerUser', id);
          }
        }
      }
      setLoading(false);
    })();
  }, [id, reload]);

  const handleSubmit = useCallback(async () => {
    setSending(true);
    const res = await handlePostTicketMessage({
      Message: message,
      TicketID: id,
      Status: '-'
    });
    if (res.ok) {
      await handlePutTicket(props.isAdmin ? 'PendingViewUser' : 'PendingViewAdmin', id);
      setReload(!reload);
      setMessage('');
    }
    setSending(false);
  }, [id, message, reload]);
  return (
    <>
      {data && !loading ? (
        <div className=" mx-10">
          <h5 className="text-center">{data.Title}</h5>
          <div className="flex flex-row justify-between">
            {/* <h5 className="invisible">#{data.ID}</h5> */}
            <h5>{TICKET_CATEGORY_CONVERTOR[data.Section]}</h5>
            <h5>
              {props.isAdmin
                ? TICKET_STATUS_CONVERTOR[data.Status]?.admin
                : TICKET_STATUS_CONVERTOR[data.Status]?.user}
            </h5>
            <h5>#{data.ID}</h5>
          </div>
          <TicketMessages />
          <div className="mt-3">
            <Input
              as="textarea"
              className="!h-[140px]"
              dir="rtl"
              placeholder="پاسخ"
              value={message}
              disabled={sending}
              onChange={setMessage}
            />
            <div className="flex flex-row-reverse mt-2">
              <Button appearance="primary" onClick={handleSubmit} loading={sending}>
                ارسال
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};
