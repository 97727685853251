/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react';
import { Button, Col, FlexboxGrid, Form, Input, InputPicker, Modal, Schema } from 'rsuite';
import TextField from '../../../components/form/textfield';
import { TICKET_CATEGORY_CONVERTOR } from '../../../utils/helpers';
import Field from '../../../components/form/field';
import { handlePostTicketAndMessage } from '../../../api-handler';
import TextAreaField from '../../../components/form/textarea-field';

const model = Schema.Model({});

export const NewTicketModal = (props: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }
    setLoading(true);
    const ticketPayload = {
      Title: formValue.Title,
      Section: formValue.Section
    };
    const messagePayload = { Message: formValue.Message };
    const res = await handlePostTicketAndMessage(ticketPayload, messagePayload);
    if (res.ok) {
      props.onClose();
    }
    setLoading(false);
  }, [formValue, formError, props]);
  return (
    <Modal backdrop={true} size="lg" keyboard={true} open={props.isOpen} onClose={props.onClose}>
      <Modal.Header>
        <Modal.Title className="text-center pe-0 !font-bold !pl-6">
          موضوع و دسته بندی تیکت را مشخص کنید
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="!mt-8">
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          model={model}
          onSubmit={handleSubmit}
          checkTrigger="change"
          readOnly={loading}
          className="mt-8 text-dark">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
              <TextField
                name="Title"
                label="موضوع"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={12} className="max-w-[100%]">
              <Field
                name="Section"
                label="دسته بندی"
                accepter={InputPicker}
                data={Object.keys(TICKET_CATEGORY_CONVERTOR).map((item: any) => {
                  return { label: TICKET_CATEGORY_CONVERTOR[item], value: item };
                })}
                dir="rtl"
                full
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={24} className="max-w-[100%]">
              <TextAreaField name="Message" label="توضیحات" dir="rtl" full req placeholder="" />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Button
            appearance="primary"
            type="submit"
            className="w-[50%] mx-auto !block mt-8"
            loading={loading}>
            ثبت تیکت
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
