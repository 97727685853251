/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { convertDateToSlashi, FormatDateTime, sortData } from '../../../utils/helpers';
import { Cell, HeaderCell, Table } from 'rsuite-table';
import { Button, Col, FlexboxGrid, Form, Input, Loader, Schema } from 'rsuite';
import { Column } from 'rsuite-table';
import { useNavigate } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale/fa-IR';
import TextField from '../../../components/form/textfield';
import Field from '../../../components/form/field';
import { Datepicker } from '@ijavad805/react-datepicker';

const model = Schema.Model({});
export const PaymentsResume = ({ defaultData }: { defaultData: any }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState('ID');
  const [sortType, setSortType] = useState<any>('decs');
  const [tableLoading, setTableLoading] = useState(false);

  const handleSortColumn = (_sortColumn: any, _sortType: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(_sortColumn);
      setSortType(_sortType);
    }, 500);
  };

  const [timeStampStart, setTimeStampStart] = useState<any>(new Date(2023, 1, 1));
  const [timeStampEnd, setTimeStampEnd] = useState<any>(new Date());

  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading] = useState(false);
  const handleSubmit = useCallback(() => {
    console.log(formValue);
    setData(
      defaultData.filter((d: any) => {
        if (
          !formValue.RefID &&
          !formValue.Username &&
          !formValue.FirstName &&
          !formValue.LastName &&
          !formValue.BuyerUsername &&
          !formValue.BuyerFirstName &&
          !formValue.BuyerLastName &&
          !formValue.TimeStampStart &&
          !formValue.TimeStampEnd
        ) {
          return true;
        }
        if (
          // (formValue.NID
          //   ? d.JobSeekerInfo?.NID.toLowerCase().search(formValue.NID.toLowerCase()) >= 0
          //   : true) &&
          (formValue.RefID
            ? d.RefID.toLowerCase().search(formValue.RefID.toLowerCase()) >= 0
            : true) &&
          (formValue.Username
            ? d.Username?.Username.toLowerCase().search(formValue.Username.toLowerCase()) >= 0
            : true) &&
          (formValue.FirstName
            ? d.Username?.FirstName.toLowerCase().search(formValue.FirstName.toLowerCase()) >= 0
            : true) &&
          (formValue.LastName
            ? d.Username?.LastName.toLowerCase().search(formValue.LastName.toLowerCase()) >= 0
            : true) &&
          (formValue.BuyerUsername
            ? d.BuyerUsername?.Username.toLowerCase().search(
                formValue.BuyerUsername.toLowerCase()
              ) >= 0
            : true) &&
          (formValue.BuyerFirstName
            ? d.BuyerUsername?.FirstName.toLowerCase().search(
                formValue.BuyerFirstName.toLowerCase()
              ) >= 0
            : true) &&
          (formValue.BuyerLastName
            ? d.BuyerUsername?.LastName.toLowerCase().search(
                formValue.BuyerLastName.toLowerCase()
              ) >= 0
            : true) &&
          (formValue.TimeStampStart
            ? new Date(formValue.TimeStampStart).getTime() <= new Date(d.BoughtTimeStamp).getTime()
            : true) &&
          (formValue.TimeStampEnd
            ? new Date(formValue.TimeStampEnd).getTime() >= new Date(d.BoughtTimeStamp).getTime()
            : true)
        ) {
          return true;
        }
      })
    );
  }, [formValue, defaultData, timeStampEnd, timeStampStart]);

  useEffect(() => {
    handleSubmit();
  }, [!!defaultData]);

  return (
    <>
      <div className="mb-6">
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          model={model}
          onSubmit={handleSubmit}
          checkTrigger="change"
          readOnly={loading}
          className="mt-8 text-dark">
          <FlexboxGrid>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Username"
                label="نام کاربری"
                type="text"
                accepter={Input}
                full
                dir="ltr"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="FirstName"
                label="نام"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="LastName"
                label="نام خانوادگی"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="BuyerUsername"
                label="نام کاربری خریدار"
                type="text"
                accepter={Input}
                full
                dir="ltr"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="BuyerFirstName"
                label="نام خریدار"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="BuyerLastName"
                label="نام خانوادگی خریدار"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="RefID"
                label="شماره پرداخت"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="TimeStampStart"
                lang={'fa'}
                allowClear={false}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStampStart)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStampStart(new Date(val))}
                label="شروع تاریخ پرداخت"
                dir="ltr"
                full
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                allowClear={false}
                className="font-fd"
                name="TimeStampEnd"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStampEnd)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStampEnd(new Date(val))}
                label="پایان تاریخ پرداخت"
                dir="ltr"
                full
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Button appearance="primary" type="submit" className="w-[50%] mx-auto !block mt-8">
            جست و جو
          </Button>
        </Form>
      </div>
      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">پرداختی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell> نام کاربری خریدار</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.BuyerUsername?.Username}</>;
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام و نام خانوادگی خریدار</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <>
                  <Button
                    appearance="primary"
                    size="xs"
                    type="button"
                    onClick={() => navigate(`/admin/profile/${rowData.BuyerUsername?.Username}`)}>
                    {' '}
                    {rowData.BuyerUsername?.FirstName} {rowData.BuyerUsername?.LastName}
                  </Button>
                </>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell> نام کاربری </HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.Username?.Username}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام و نام خانوادگی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return (
                <>
                  <Button
                    appearance="primary"
                    size="xs"
                    type="button"
                    onClick={() => navigate(`/admin/profile/${rowData.Username?.Username}`)}>
                    {' '}
                    {rowData.Username?.FirstName} {rowData.Username?.LastName}
                  </Button>
                </>
              );
            }}
          </Cell>
        </Column>

        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>شماره پرداخت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.RefID}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center font-fd">
          <HeaderCell>زمان پرداخت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{FormatDateTime(rowData.BoughtTimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
