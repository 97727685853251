import { Button, Checkbox, Col, Form, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

const { StringType } = Schema.Types;
const model = Schema.Model({
  ClientProblem: StringType('').isRequired('الزامی است!'),
  ClientQuestions: StringType('').isRequired('الزامی است!'),
  ClientStatus: StringType('').isRequired('الزامی است!'),
  ConsultantSolution: StringType('').isRequired('الزامی است!'),
  // needMoreConsult: BooleanType('').isRequired('الزامی است!'),
  // NeedAnotherTypeOfConsult: BooleanType('').isRequired('الزامی است!'),
  TypeOfConsultThatClientNeed: StringType('')
});

// import { handlePostConsultantReport } from '../../api-handler';
// import { showToast } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';
// import Field from '../../components/form/field';
import TextAreaField from '../../components/form/textarea-field';
import { useParams } from 'react-router-dom';
import { showToast } from '../../utils/toast';
import {
  handleGetConsultReportByConsultant,
  handlePostConsultReportByConsultant
} from '../../api-handler/consult';
import Field from '../../components/form/field';

export const ConsultantMeetingReport = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { id, consultantUsername, counselorUsername } = useParams();
  const [needAnotherTypeOfConsult, setNeedAnotherTypeOfConsult] = useState(false);
  const [needMoreConsult, setNeedMoreConsult] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = {
      ...formValue,
      ConsultID: parseInt(id ?? '-1'),
      NeedAnotherTypeOfConsult: needAnotherTypeOfConsult,
      NeedMoreConsult: needMoreConsult,
      ConsultantUsername: consultantUsername,
      CounselorUsername: counselorUsername
    };
    setLoading(true);
    // if (editMode) {
    //   setLoading(false);
    //   return;
    // }
    const res = await handlePostConsultReportByConsultant(payload);
    if (res.ok) {
      showToast('فرم با موفقیت ثبت شد!', 'success');
      // showToast('فرم با موفقیت ثبت شد و پش از تایید توسط ادمین نمایش داده خواهد شد!', 'success');
      // todo: navigate to next step
      navigate('/consultant/requests');
    }
    setLoading(false);
  }, [formValue, formError, formRef, id, needMoreConsult, needAnotherTypeOfConsult]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetConsultReportByConsultant(parseInt(id ?? '-1'));
      if (res.ok) {
        setFormValue(res.data);
        setNeedAnotherTypeOfConsult(res.data.NeedAnotherTypeOfConsult);
        setNeedMoreConsult(res.data.NeedMoreConsult);
        setEditMode(true);
      }
      setLoading(false);
    })();
  }, [id]);

  return (
    <>
      <h4 className="text-h4">نتیجه جلسه تان را با ما به اشتراک بگذارید</h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading || editMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="ClientProblem"
              label="مشکل مشاوره گیرنده"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="ClientQuestions"
              label="سوالات مشاوره گیرنده"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="ConsultantSolution"
              label="راه حل های مشاوره دهنده"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="ClientStatus"
              label="وضعیت مشاوره گیرنده"
              dir="rtl"
              full
              req
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <Field
              name="NeedMoreConsult2"
              onChange={(value: string | number, checked: boolean) => {
                setNeedMoreConsult(checked);
              }}
              label=""
              checked={needMoreConsult}
              accepter={Checkbox}
              dir="rtl"
              full>
              نیاز به مشاوره بیشتری دارد؟
            </Field>
            <Field
              name="NeedAnotherTypeOfConsult2"
              onChange={(value: string | number, checked: boolean) => {
                setNeedAnotherTypeOfConsult(checked);
              }}
              label=""
              checked={needAnotherTypeOfConsult}
              accepter={Checkbox}
              dir="rtl"
              full>
              نیاز به نوع دیگری از مشاوره دارد؟
            </Field>
          </FlexboxGrid.Item>
          {needAnotherTypeOfConsult ? (
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
              <TextAreaField
                name="TypeOfConsultThatClientNeed"
                label="نوع مشاوره مورد نیاز"
                dir="rtl"
                full
                req
                placeholder=""
              />
            </FlexboxGrid.Item>
          ) : null}
          {/* <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <Field name="Score" label="امتیاز کلی شما" full accepter={Rate} color="yellow" />
          </FlexboxGrid.Item> */}
        </FlexboxGrid>
        <div className="flex justify-between">
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="submit"
            loading={loading}
            disabled={editMode}>
            ثبت نتیجه
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ConsultantMeetingReport;
