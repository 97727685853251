import { Schema } from 'rsuite';

const { StringType } = Schema.Types;
export interface ConsultantFormValueModel {
  FirstName: String;
  LastName: String;
  NID: Number;
  // Sex: 'خانم' | 'آقا';
  MilitaryService: String;
  FathersName: String;
  Email: String;
  // CountryB: String;
  StateB: String;
  CityB: String;
  CellPhone: Number;
  Website: String;
  // Birth_Date: String | Date;
  RID?: Number;
  EduDegree: String;
  // HasCompany: Boolean;
  Role?: String;
  CompanyName?: String;
  CompanyID?: String;
  CompanyEmail?: String;
  CompanyPhone?: String;
  CompanyType?: String;
  CompanySite?: String;
  CompanyAddress?: String;
  CompanyField?: String;
}

export interface PostConsultantPersonalModel extends ConsultantFormValueModel {
  Sex: Boolean;
  CountryB: String;
  Birth_Date: String | Date;
  HasCompany: Boolean;
}

export const ConsultantPersonalFormSchema = Schema.Model({
  FirstName: StringType('').isRequired('الزامی است!'),
  LastName: StringType('').isRequired('الزامی است!'),
  NID: StringType('').isRequired('الزامی است!'),
  // Sex: StringType('').isRequired('الزامی است!'),
  MilitaryService: StringType(''),
  FathersName: StringType('').isRequired('الزامی است!'),
  Email: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'فرمت وارد شده اشتباه است!'),
  // CountryB: StringType('').isRequired('الزامی است!'),
  StateB: StringType(''),
  CityB: StringType('').isRequired('الزامی است!'),
  CellPhone: StringType('')
    .isRequired('الزامی است!')
    .pattern(/^0[0-9]{10}$/, 'شماره تلفن را با صفر و بدون کد کشور وارد کنید!'),
  Website: StringType('').pattern(
    /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
    'فرمت وبسایت وارد شده اشتباه است! مثال https://cbcs.ir'
  ),
  // Birth_Date: StringType('').isRequired('الزامی است!'),
  // RID: StringType('').isRequired('الزامی است!'),
  EduDegree: StringType('').isRequired('الزامی است!'),
  Role: StringType(''),
  // HasCompany:
  CompanyName: StringType(''),
  CompanyID: StringType(''),
  CompanyEmail: StringType('').pattern(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'فرمت وارد شده اشتباه است!'
  ),
  CompanyPhone: StringType('').pattern(
    /^0[0-9]{10}$/,
    'شماره تلفن را با صفر و بدون کد کشور وارد کنید!'
  ),
  CompanyType: StringType(''),
  CompanySite: StringType(''),
  CompanyAddress: StringType(''),
  CompanyField: StringType('')
});

// work
export interface ConsultantWorkBackgroundFormValueModel {
  ID?: String;
  Name: String;
  Role: String;
  NID: Number;
  // Country: String;
  State: String;
  City: String;
  // Start_Date?: String | Date;
  Address: String;
  Duration?: String;
  // CurrentWork: Boolean;
}

export interface PostConsultantWorkBackgroundModel extends ConsultantWorkBackgroundFormValueModel {
  Country: String;
  Start_Date?: String | Date;
  CurrentWork?: Boolean;
}

// education
export interface ConsultantEducationBackgroundFormValueModel {
  // Username: String;
  ID?: String;
  Name: String;
  // Country: String;
  State: String;
  City: String;
  Address: String;
  Description: String;
  Field: String;
  Orientation: String;
  Grade: String;
  // StudyNow: boolean;
  // Start_Date: String;
  // End_Date: String;
}
export const defaultConsultantEducationBackgroundFormValue = {
  Name: '',
  State: '',
  City: '',
  Address: '',
  Description: '',
  Field: '',
  Orientation: '',
  Grade: '',
  Start_Date: new Date(),
  End_Date: new Date()
};

export const defaultConsultantEducationBackgroundSchema = Schema.Model({
  Name: StringType('').isRequired('الزامی است!'),
  // Country: StringType('').isRequired('الزامی است!'),
  State: StringType(''),
  City: StringType('').isRequired('الزامی است!'),
  // Start_Date: StringType('').isRequired('الزامی است!'),
  // End_Date: StringType('').isRequired('الزامی است!'),
  Address: StringType('').isRequired('الزامی است!'),
  Description: StringType(''),
  Field: StringType('').isRequired('الزامی است!'),
  Orientation: StringType(''),
  Grade: StringType('').isRequired('الزامی است!')
});

export interface PostConsultantEducationBackgroundModel
  extends ConsultantEducationBackgroundFormValueModel {
  StudyNow: boolean;
  Country: String;
  Start_Date: String;
  End_Date?: String;
}

// consultation
// 'ID',
// '*Username',
// '*Field',
// '*Period',
// 'NameCounselor',
// 'Result',
// 'WorkedOn'
export interface ConsultantConsultationBackgroundFormValueModel {
  // Username: String;
  ID?: String;
  Field: String;
  Period: String;
  NameCounselor?: String;
  Result?: String;
  WorkedOn?: String;
}
export const defaultConsultantConsultationBackgroundFormValue = {
  Field: '',
  Period: '',
  NameCounselor: '',
  Result: '',
  WorkedOn: ''
};

export const defaultConsultantConsultationBackgroundSchema = Schema.Model({
  Field: StringType('').isRequired('الزامی است!'),
  Period: StringType('').isRequired('الزامی است!'),
  NameCounselor: StringType('').isRequired('الزامی است!'),
  Result: StringType('').isRequired('الزامی است!'),
  WorkedOn: StringType('').isRequired('الزامی است!')
});

export interface PostConsultantConsultationBackgroundModel
  extends ConsultantConsultationBackgroundFormValueModel {}

// social
export interface ConsultantSocialFormValueModel {
  // Username: String;
  Instagram?: String;
  WhatsApp?: String;
  Linkedin?: String;
  Telegram?: String;
  Github?: String;
  Karlancer?: String;
}
export const defaultConsultantSocialFormValue = {
  Instagram: '',
  WhatsApp: '',
  Linkedin: '',
  Telegram: '',
  Github: '',
  Karlancer: ''
};
export interface PostConsultantSocialModel extends ConsultantSocialFormValueModel {}
