/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { handleGetAdminTickets, handleGetCurrentUserTickets } from '../../api-handler';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import {
  convertDateToSlashi,
  FormatDateTime,
  formatIncomingPhoneNumber,
  sortData,
  TICKET_CATEGORY_CONVERTOR,
  TICKET_STATUS_CONVERTOR
} from '../../utils/helpers';
import { Button, Col, FlexboxGrid, Form, Input, InputPicker, Loader, Schema } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import TextField from '../../components/form/textfield';
import Field from '../../components/form/field';
import { Datepicker } from '@ijavad805/react-datepicker';
import { faIR } from 'date-fns-jalali/locale/fa-IR';
import { NewTicketModal } from './popups/ticket-modal';
const model = Schema.Model({});

export const Tickets = (props: { isAdmin?: boolean }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [loading] = useState(false);
  const [sortColumn, setSortColumn] = useState('ID');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sortType, setSortType] = useState<any>('asc');
  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [defaultData, setDefaultData] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    (async () => {
      setTableLoading(true);
      let res;
      if (props.isAdmin) {
        res = await handleGetAdminTickets();
      } else {
        res = await handleGetCurrentUserTickets();
      }
      if (res.ok) {
        setData(res.data);
        setDefaultData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  const [timeStampStart, setTimeStampStart] = useState<any>(new Date(2023, 1, 1));
  const [timeStampEnd, setTimeStampEnd] = useState<any>(new Date());

  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const handleSubmit = useCallback(() => {
    console.log(formValue);
    setData(
      defaultData.filter((d) => {
        if (
          // !formValue.Username &&
          !formValue.NID &&
          !formValue.FirstName &&
          !formValue.CellPhone &&
          !formValue.LastName &&
          !formValue.Section &&
          !formValue.Title &&
          !formValue.TimeStampStart &&
          !formValue.TimeStampEnd &&
          !formValue.Status
        ) {
          return true;
        }
        if (
          (formValue.NID
            ? d.UserInfo?.NID.toLowerCase().search(formValue.NID.toLowerCase()) >= 0
            : true) &&
          // (formValue.Username
          //   ? d.Username.toLowerCase().search(formValue.Username.toLowerCase()) >= 0
          //   : true) &&
          (formValue.CellPhone
            ? formatIncomingPhoneNumber(d.UserInfo?.CellPhone)
                .toLowerCase()
                .search(formValue.CellPhone.toLowerCase()) >= 0
            : true) &&
          (formValue.FirstName
            ? d.UserInfo?.FirstName.toLowerCase().search(formValue.FirstName.toLowerCase()) >= 0
            : true) &&
          (formValue.LastName
            ? d.UserInfo?.LastName.toLowerCase().search(formValue.LastName.toLowerCase()) >= 0
            : true) &&
          (formValue.Status
            ? d.Status.toLowerCase().search(formValue.Status.toLowerCase()) >= 0
            : true) &&
          (formValue.Section
            ? d.Section.toLowerCase().search(formValue.Section.toLowerCase()) >= 0
            : true) &&
          (formValue.Title
            ? d.Title.toLowerCase().search(formValue.Title.toLowerCase()) >= 0
            : true) &&
          (formValue.TimeStampStart
            ? new Date(formValue.TimeStampStart).getTime() <= new Date(d.TimeStamp).getTime()
            : true) &&
          (formValue.TimeStampEnd
            ? new Date(formValue.TimeStampEnd).getTime() >= new Date(d.TimeStamp).getTime()
            : true)
        ) {
          return true;
        }
      })
    );
  }, [formValue, defaultData, timeStampEnd, timeStampStart]);

  useEffect(() => {
    handleSubmit();
  }, [!!defaultData]);

  const [isOpenTicket, setIsOpenTicket] = useState(false);
  return (
    <>
      <div className="flex flex-row justify-between">
        <h4 className="text-h4 mb-6">لیست تیکت ها</h4>
        <Button appearance="primary" onClick={() => setIsOpenTicket(true)}>
          تیکت جدید
        </Button>
      </div>
      <div className="mb-6">
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formError={formError}
          model={model}
          onSubmit={handleSubmit}
          checkTrigger="change"
          readOnly={loading}
          className="mt-8 text-dark">
          <FlexboxGrid>
            {/* <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Username"
                label="نام کاربری"
                type="text"
                accepter={Input}
                full
                dir="ltr"
                placeholder=""
              />
            </FlexboxGrid.Item> */}
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <TextField
                name="Title"
                label="موضوع"
                type="text"
                accepter={Input}
                full
                dir="rtl"
                placeholder=""
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <Field
                name="Section"
                label="دسته بندی"
                accepter={InputPicker}
                data={[
                  { label: 'تایید شده', value: 'Accepted' },
                  { label: 'در انتظار تایید', value: 'Pending' },
                  { label: 'رفع نقص', value: 'Rejected' },
                  { label: 'تعلیق شده', value: 'Suspended' },
                  { label: 'مختومه', value: 'Terminated' }
                ]}
                dir="rtl"
                full
              />
            </FlexboxGrid.Item>
            {props.isAdmin ? (
              <>
                <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
                  <TextField
                    name="NID"
                    label="کد ملی"
                    type="text"
                    accepter={Input}
                    full
                    dir="ltr"
                    placeholder=""
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
                  <TextField
                    name="CellPhone"
                    label="شماره تماس"
                    type="text"
                    accepter={Input}
                    full
                    dir="ltr"
                    placeholder=""
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
                  <TextField
                    name="FirstName"
                    label="نام"
                    type="text"
                    accepter={Input}
                    full
                    dir="rtl"
                    placeholder=""
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
                  <TextField
                    name="LastName"
                    label="نام خانوادگی"
                    type="text"
                    accepter={Input}
                    full
                    dir="rtl"
                    placeholder=""
                  />
                </FlexboxGrid.Item>
              </>
            ) : null}
            <FlexboxGrid.Item as={Col} sm={24} md={8} className="max-w-[100%]">
              <Field
                name="Status"
                label="وضعیت"
                accepter={InputPicker}
                data={Object.keys(TICKET_STATUS_CONVERTOR).map((item: any) => {
                  return {
                    label: props.isAdmin
                      ? TICKET_STATUS_CONVERTOR[item]?.admin
                      : TICKET_STATUS_CONVERTOR[item]?.user,
                    value: item
                  };
                })}
                dir="rtl"
                full
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                className="font-fd"
                name="TimeStampStart"
                lang={'fa'}
                allowClear={false}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStampStart)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStampStart(new Date(val))}
                label="شروع تاریخ آخرین تغییر"
                dir="ltr"
                full
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={8} className="max-w-[100%]">
              <Field
                accepter={Datepicker}
                allowClear={false}
                className="font-fd"
                name="TimeStampEnd"
                lang={'fa'}
                theme={'green'}
                format={'D MMMM yyyy'}
                value={convertDateToSlashi(timeStampEnd)}
                input={<Input className="font-fd" type="text" dir="ltr" placeholder="" />}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(val: any) => setTimeStampEnd(new Date(val))}
                label="پایان تاریخ آخرین تغییر"
                dir="ltr"
                full
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Button appearance="primary" type="submit" className="w-[50%] mx-auto !block mt-8">
            جست و جو
          </Button>
        </Form>
      </div>
      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">درخواستی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center" sortable>
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>موضوع</HeaderCell>
          <Cell dataKey="Title" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>دسته بندی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {TICKET_CATEGORY_CONVERTOR[rowData.Section]}</>;
            }}
          </Cell>
        </Column>
        {props.isAdmin ? (
          <>
            {/* <Column flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>نام کاربری</HeaderCell>
              <Cell dataKey="Username" />
            </Column> */}

            <Column flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>کد ملی</HeaderCell>
              <Cell dataKey="">
                {(rowData) => {
                  return <> {rowData.UserInfo?.NID}</>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>نام</HeaderCell>
              <Cell dataKey="">
                {(rowData) => {
                  return <> {rowData.UserInfo?.FirstName}</>;
                }}
              </Cell>
            </Column>
            <Column flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>نام خانوادگی</HeaderCell>
              <Cell dataKey="">
                {(rowData) => {
                  return <> {rowData.UserInfo?.LastName}</>;
                }}
              </Cell>
            </Column>
          </>
        ) : null}

        {props.isAdmin ? (
          <Column flexGrow={1} verticalAlign="middle" align="center">
            <HeaderCell>وضعیت</HeaderCell>
            <Cell dataKey="">
              {(rowData) => {
                return <> {TICKET_STATUS_CONVERTOR[rowData.Status]?.user}</>;
              }}
            </Cell>
          </Column>
        ) : (
          <Column flexGrow={1} verticalAlign="middle" align="center">
            <HeaderCell>وضعیت</HeaderCell>
            <Cell dataKey="">
              {(rowData) => {
                return <> {TICKET_STATUS_CONVERTOR[rowData.Status]?.user}</>;
              }}
            </Cell>
          </Column>
        )}
        {/* <Column flexGrow={1} verticalAlign="middle" align="center">
              <HeaderCell>شماره تلفن</HeaderCell>
              <Cell dataKey="" dir="ltr">
                {(rowData) => {
                  return <> {formatIncomingPhoneNumber(rowData.UserInfo?.CellPhone)}</>;
                }}
              </Cell>
            </Column> */}
        <Column flexGrow={1} width={220} verticalAlign="middle" align="center font-fd" sortable>
          <HeaderCell>زمان</HeaderCell>
          <Cell dataKey="UpdateTimeStamp">
            {(rowData) => {
              return <>{FormatDateTime(rowData.UpdateTimeStamp ?? rowData.TimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
        <Column width={90} verticalAlign="middle" align="center">
          <HeaderCell>مشاهده</HeaderCell>
          <Cell dataKey="">
            {(rowData) => (
              <Button
                appearance="primary"
                size="xs"
                type="button"
                onClick={() => navigate(`/ticket/${rowData.ID}`)}>
                مشاهده
              </Button>
            )}
          </Cell>
        </Column>
      </Table>

      <NewTicketModal
        onOpen={() => setIsOpenTicket(true)}
        onClose={() => {
          setIsOpenTicket(false);
        }}
        isOpen={isOpenTicket}
      />
    </>
  );
};
