/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns-jalali';
import seekerCategoryOptionss from '../data/seeker/seeker-category.json';
import seekerCategory2Optionss from '../data/seeker/seeker-category-2.json';
const seekerCategoryOptions: any = seekerCategoryOptionss;
const seekerCategory2Options: any = seekerCategory2Optionss;

export const getArrayFilled = (count = 10, min = 0) => {
  return Array.from(Array(count + min).keys());
};
export const getRandomId = () => (Math.random().toString(36) + '00000000000000000').slice(2, 10);

export const formatOutgoingPhoneNumber = (phone: string) => `+98${phone.substring(1)}`;
export const formatIncomingPhoneNumber = (phone: string) => `09${phone.substring(3)}`;
export const phoneNumberToUsername = (phone: string) => `${phone.substring(1)}`;
export const durationToHour = (duration: number) => {
  return duration / 3600000 === 1 ? 'یک ساعت' : 'نیم ساعت';
};
export const convertDateToSlashi = (date: Date) => {
  const d = new Date(date);
  return `${d?.getFullYear()}/${d?.getMonth() + 1}/${d?.getDate()}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortData = (data: any, sortColumn: any, sortType: any) => {
  if (sortColumn && sortType) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.sort((a: any, b: any) => {
      const x = a[sortColumn];
      const y = b[sortColumn];
      if (typeof x === 'string') {
        if (sortType === 'asc') {
          return y.localeCompare(x);
        } else {
          return x.localeCompare(y);
        }
      }
      if (sortType === 'asc') {
        return y - x;
      } else {
        return x - y;
      }
    });
  }
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const STATUS_CONVERTOR: any = {
  Null: {
    default: '',
    short: '',
    isEditAvailable: true,
    stillHasRole: false
  },
  Pending: {
    default: 'در انتظار تایید',
    short: 'در انتظار',
    isEditAvailable: false,
    stillHasRole: false
  },
  Rejected: {
    default: 'رفع نقص',
    short: 'رفع نقص',
    isEditAvailable: true,
    stillHasRole: false
  },
  Accepted: {
    default: 'تایید شده',
    short: 'تایید',
    isEditAvailable: false,
    stillHasRole: true
  },
  Suspended: {
    default: 'تعلیق شده',
    short: 'تعلیق',
    isEditAvailable: true,
    stillHasRole: true
  },
  Terminated: {
    default: 'مختومه',
    short: 'مختومه',
    stillHasRole: false,
    isEditAvailable: true
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SESSION_STATUS_CONVERTOR: any = {
  PendingConsultant: 'درانتظار تایید مشاور',
  PendingUser: 'درانتظار تایید کاربر',
  PendingMeeting: 'تایید شده',
  PendingDone: 'زمان و مکان ست شده',
  Done: 'پایان یافته'
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ROLE_CONVERTOR: any = {
  User: 'کاربر',
  Admin: 'ادمین',
  Consultant: 'مشاور',
  Corporation: 'کسب و کار',
  Seeker: 'کارجو'
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RESUME_REQ_STATUS: any = {
  JSPending: 'در انتظار تایید توسط کارجو',
  EPPending: 'در انتظار پرداخت توسط شما',
  Rejected: 'رد شده توسط کارجو',
  Paid: 'پرداخت شده'
};
export const RESUME_REQ_STATUS_BY_USER: any = {
  JSPending: 'در انتظار تایید توسط شما',
  EPPending: 'در انتظار پرداخت توسط کسب و کار',
  Rejected: 'رد شده توسط شما',
  Paid: 'در اختیار کسب و کار'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TICKET_STATUS_CONVERTOR: any = {
  PendingViewAdmin: {
    user: 'در انتظار مشاهده',
    admin: 'پاسخ جدید'
  },
  PendingAnswerAdmin: {
    user: 'درحال برسی',
    admin: 'مشاهده شده'
  },
  PendingViewUser: {
    user: 'پاسخ جدید',
    admin: 'در انتظار مشاهده کاربر'
  },
  PendingAnswerUser: {
    user: 'مشاهده شده',
    admin: 'در انتظار پاسخ'
  },
  Closed: {
    user: 'بسته شده',
    admin: 'بسته شده'
  }
  // Created: {
  //   user: 'ساخته شده',
  //   admin: 'ساخته شده'
  // }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TICKET_CATEGORY_CONVERTOR: any = {
  finance: 'مالی',
  technical: 'فنی',
  report: 'گزارش'
};
export const base64ToJson = (b64: string, hasDefault = false) => {
  try {
    const res = JSON.parse(decodeURIComponent(escape(atob(b64))));
    return hasDefault ? (res.length ? res : [false]) : res;
  } catch (e) {
    console.log(e);
  }

  return hasDefault ? [false] : [];
};
export const jsonToBase64 = (json: string) => {
  try {
    return btoa(unescape(encodeURIComponent(JSON.stringify(json))));
  } catch (e) {
    console.log(e);
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAge = (dateString: any) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const MEETING_ADDRESSES: any = {
  online: [
    'https://panel.cbcs.ir/room1',
    'https://panel.cbcs.ir/room2',
    'https://panel.cbcs.ir/room3',
    'https://panel.cbcs.ir/room4',
    'https://panel.cbcs.ir/room5'
  ],
  offline: [
    'شیراز - بلوار دکتر حسابی (صنایع) - شهرک آرین - پارک علم و فناوری فارس - ساختمان مرکز رشد جامع - طبقه دوم - واحد 6208'
  ],
  mapper: {
    1: 'https://meet.google.com/crg-cywj-hop',
    2: 'https://meet.google.com/bfj-saqa-twq',
    3: 'https://meet.google.com/djx-wmfx-ksw',
    4: 'https://meet.google.com/hna-cghy-brg',
    5: 'https://meet.google.com/tqa-huzg-mja'
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CONSULT_REPORT_PC: any = {
  pros: {
    p1: 'دانش کافی مشاور',
    p2: 'تجربه و مهارت در زمینه تخصصی مشاوره',
    p3: 'رعایت اصول حرفه ای مشاوره',
    p4: 'ارائه نظرات و پیشنهادهای کاربردی',
    p5: 'ارتباط اجتماعی مناسب',
    p6: 'رعایت ادب و احترام در جلسه مشاوره',
    p7: 'رعایت زمان بندی و استفاده بهینه از زمان جلسه مشاوره',
    p8: 'درک صحیح از مشکل شرکت'
  },
  cons: {
    c1: 'نداشتن دانش کافی مشاور',
    c2: 'عدم تجربه و مهارت در زمینه تخصصی',
    c3: 'رعایت نکردن اصول حرفه ای مشاوره و حقوق افراد در جلسه',
    c4: 'پیشنهاد کاربردی و مفیدی ارائه نگردید',
    c5: 'مشاور، در برقراری ارتباط اجتماعی ضعف داشت',
    c6: 'بی احترامی و بی ادبی در حین مشاوره',
    c7: 'رعایت نکردن زمان بندی جلسه و عدم استفاده بهینه از زمان جلسه مشاوره',
    c8: 'نداشتن توانایی در درک مشکل شرکت'
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatDate = (time: any, locale: any) => {
  try {
    return format(time, 'dd MMMM yyyy', { locale });
  } catch (e) {
    console.log(e);
  }
  return '-';
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatDateTime = (time: any, locale: any) => {
  try {
    return format(time, 'dd MMMM yyyy ساعت HH:mm', { locale });
  } catch (e) {
    console.log(e);
  }
  return '-';
};
export const FormatDateToBeautifulMonthAndYear = (time: any, locale: any) => {
  try {
    return format(time, 'MMMM‌ماه yyyy', { locale });
  } catch (e) {
    console.log(e);
  }
  return '-';
};
export const FormatDateToBeautifulYear = (time: any, locale: any) => {
  try {
    return format(time, 'yyyy', { locale });
  } catch (e) {
    console.log(e);
  }
  return '-';
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatYear = (time: any, locale: any) => {
  try {
    return format(time, 'yyyy', { locale });
  } catch (e) {
    console.log(e);
  }
  return '-';
};

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Num2persian: any;
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HandleNum2persian = (number: any, postFix?: any) => {
  try {
    return `${window.Num2persian(number)} ${postFix}`;
  } catch (e) {
    return '';
  }
};

export const SEEKER_CATGORY1_CONVERTOR = (value: any) => {
  const items = seekerCategoryOptions.filter((item: any) => item.value == value);
  if (items) {
    const item = items[0];
    return item?.label;
  }
  return '';
};
export const SEEKER_CATGORY2_CONVERTOR = (value1: any, value2: any) => {
  const items = seekerCategory2Options[value1]?.filter((item: any) => item.value == value2);
  if (items) {
    const item = items[0];
    return item?.label;
  }
  return '';
};

export const formatCategory = (category: any) => {
  if (category) {
    if (category[0] == '{') {
      const category2 = category.slice(1, -1);
      return category2.replaceAll(/"/g, '').replaceAll(',', ', ');
    } else {
      return category;
    }
  }
  return '';
};
