import { useEffect, useState } from 'react';
import { handleGetPayments } from '../../../api-handler/admin';
import { PaymentsResume } from './payments-resume';
import { CustomLoader } from '../../../components/basic/loader';
import { PaymentsConsult } from './payments-consult';
import { PaymentsWorkshop } from './payments-workshop';
import { Col, FlexboxGrid, Radio, RadioGroup } from 'rsuite';

export const AdminPayments = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [exportType, setExportType] = useState<any>('مشاوره');

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetPayments();
      if (res.ok) {
        setData(res.data);
      }
      setLoading(false);
    })();
  }, []);

  // const [search, setSearch] = useState("");

  // useEffect(() => {
  //   setData(defaultData.filter(d=>(d.Book_Name.toLowerCase().search(search.toLowerCase())>=0)))
  // }, [search]);

  return (
    <>
      <h4 className="text-h4 mb-6">لیست پرداخت ها</h4>

      <FlexboxGrid>
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24} className="max-w-[100%]">
          <label htmlFor="">
            نوع
            <RadioGroup
              name="radio-group-inline"
              inline
              defaultValue="مشاوره"
              value={exportType}
              onChange={(value, _event) => setExportType(value)}>
              <Radio value="مشاوره">مشاوره</Radio>
              <Radio value="رویداد">رویداد</Radio>
              <Radio value="رزومه">رزومه</Radio>
            </RadioGroup>
          </label>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {loading ? (
        <CustomLoader />
      ) : exportType == 'رزومه' ? (
        <PaymentsResume defaultData={data.JS_Data} />
      ) : exportType == 'رویداد' ? (
        <PaymentsWorkshop defaultData={data.WS_Data} />
      ) : (
        <PaymentsConsult defaultData={data.CS_Data} />
      )}
    </>
  );
};
