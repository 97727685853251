import { useState, useEffect } from 'react';
import { ConsultationBackgroundForm } from './components/consultation-background-form';
import { PostConsultantConsultationBackgroundModel } from '../../models';
import { Button } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';
import {
  handleDeleteConsultantConsultationBackground,
  handleGetConsultantConsultationBackground
} from '../../api-handler';
import { useNavigate } from 'react-router-dom';
import { CustomLoader } from './../../components/basic/loader';
import { showToast } from '../../utils/toast';
import { ROUTES } from '../../router';

export const ConsultantConsultationBackground = (props: {
  disabledMode?: boolean;
  isAdmin: boolean;
  username?: string;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(true);

  const [data, setData] = useState<(PostConsultantConsultationBackgroundModel | null)[]>([]);
  const [isDoing] = useState<ValueType>('no');
  const [showNew, setShowNew] = useState(true);
  const handleAddEducationData = (
    newData: PostConsultantConsultationBackgroundModel | null,
    isCurrent: boolean
  ) => {
    console.log('update array', newData, isCurrent);
    if (isCurrent) {
      return setData((prev) => {
        return [newData, ...prev.slice(1, -1)];
      });
    }
    return setData((prev) => {
      return [...prev, newData];
    });
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetConsultantConsultationBackground(
        props.isAdmin ? props.username : null
      );
      if (res.ok) {
        // because the form does not have the first option
        setData([null, ...res.data]);
        console.log(editMode);
        setEditMode(true);
        setShowNew(false);
      }
      setLoading(false);
    })();
  }, [props.username]);

  const onDelete = async (id: string | number | null) => {
    if (!id) {
      setShowNew(false);
    } else {
      const dataCopy = [...data];
      setData(dataCopy.filter((item) => item?.ID !== id));
      try {
        const res = await handleDeleteConsultantConsultationBackground(id);
        if (res.ok) {
          showToast('با موفقیت حذف شد.', 'success');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  if (loading) {
    return <CustomLoader />;
  }
  if (props.isAdmin && !data?.length) {
    return <></>;
  }
  return (
    <>
      <h4 className="text-h4">
        {props.isAdmin ? 'سوابق مشاوره ای' : 'سوابق مشاوره ای خود را وارد کنید'}
      </h4>

      {/* <FlexboxGrid className="mt-8">
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={6} lg={5} className="max-w-[100%]">
          <RadioGroup
            value={isDoing}
            dir="rtl"
            onChange={(value: ValueType, _event: SyntheticEvent<Element, Event>) =>
              setIsDoing(value)
            }>
            <div className="flex items-center">
              <div className="w-[50%]">مشغول به کار</div>
              <Radio className="w-[25%]" value={'yes'}>
                بله
              </Radio>
              <Radio className="w-[25%]" value={'no'}>
                خیر
              </Radio>
            </div>
          </RadioGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid> */}

      {data.map((item, index) => {
        return isDoing !== 'yes' && index === 0 ? null : (
          <ConsultationBackgroundForm
            key={`${item?.ID}`}
            handleAddDoingData={handleAddEducationData}
            currentDoing={index === 0}
            readonly={index === 0 && isDoing === 'yes' && item === null ? false : true}
            hideNextButton={index === 0 && isDoing === 'yes' && item === null ? true : false}
            defaultValue={item}
            isAdmin={props.isAdmin}
            disabledMode={props.disabledMode}
            hasEditMode={editMode}
            onDelete={onDelete}
          />
        );
      })}
      {editMode && showNew && !props.isAdmin ? (
        <ConsultationBackgroundForm
          handleAddDoingData={handleAddEducationData}
          currentDoing={false}
          forceReadOnly={loading}
          isAdmin={props.isAdmin}
          disabledMode={false}
          isLast={true}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : null}

      {editMode && !showNew && !props.isAdmin ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => setShowNew(true)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {!editMode && data.length && showNew ? (
        <ConsultationBackgroundForm
          handleAddDoingData={handleAddEducationData}
          currentDoing={false}
          forceReadOnly={loading}
          disabledMode={props.disabledMode}
          isLast={true}
          isAdmin={props.isAdmin}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : !editMode && isDoing === 'yes' && showNew ? (
        <ConsultationBackgroundForm
          handleAddDoingData={handleAddEducationData}
          currentDoing={true}
          forceReadOnly={loading}
          disabledMode={props.disabledMode}
          isLast={true}
          isAdmin={props.isAdmin}
          isFirst={data.length < 2}
          onDelete={onDelete}
        />
      ) : !editMode && !props.disabledMode ? (
        <Button
          appearance="primary"
          className="ml-auto mt-4"
          type="button"
          onClick={() => handleAddEducationData(null, false)}>
          افزودن سابقه جدید
        </Button>
      ) : null}

      {editMode && !props.disabledMode && !showNew ? (
        <div className="flex justify-between">
          <Button
            appearance="default"
            className="ml-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.educationBackground);
            }}
            loading={loading}>
            مرحله قبل
          </Button>
          <Button
            appearance="primary"
            className="mr-auto mt-8"
            type="button"
            onClick={() => {
              navigate(ROUTES.roles.reqConsultant.social);
            }}
            loading={loading}>
            مرحله بعد
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ConsultantConsultationBackground;
