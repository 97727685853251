/* eslint-disable @typescript-eslint/no-explicit-any */
import { faIR } from 'date-fns-jalali/locale';
import { FormatDateToBeautifulYear } from '../../../../utils/helpers';
import { Divider } from 'rsuite';

export const SeekerEducationBackgroundView = ({ data }: any) => {
  return (
    <>
      {data?.length ? (
        <div className="w-full">
          <Divider className="text-[20px] font-bold">سوابق تحصیلی</Divider>
        </div>
      ) : null}
      <ul style={{ paddingRight: '12px' }}>
        {data?.map((education: any) => {
          return (
            <li key={education.id} className="mb-3">
              <div className="text-[16px] font-bold flex flex-col">
                <div className="flex flex-row justify-between px-4 py-3 bg-[--rs-primary-50] text-[--rs-primary-800] rounded-md">
                  <div>
                    {education.Grade} {education.Field}{' '}
                    {education.Orientation ? `(${education.Orientation})` : null} - {education.Name}
                  </div>
                  <div className="flex gap-4">
                    <div>
                      {FormatDateToBeautifulYear(education.Start_Date, faIR)} {' - '}
                      {education.End_Date
                        ? FormatDateToBeautifulYear(education.End_Date, faIR)
                        : 'مشغول به تحصیل'}
                    </div>
                    <div>
                      {education.Country == 'ایران'
                        ? `${education.State} - ${education.City}`
                        : `${education.Country} - ${education.City}`}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
