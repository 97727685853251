import { Header } from './../../components/headers/header';
import { Col, FlexboxGrid } from 'rsuite';
import { LayoutProps } from './props';
import { Footer } from '../../components/footer/footer';

export const FullLayout = (props: LayoutProps) => (
  <>
    <Header />
    <FlexboxGrid className="w-full min-h-[52vh] mt-12 mb-5">
      <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={24}>
        <div className="bg-[--rs-white] p-8 rounded-md border-[1px] border-[--rs-primary-300] ml-2 mr-2 md:mr-16 md:ml-16">
          {props.children}
        </div>
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <Footer />
  </>
);
