/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createTicket,
  createTicketMessage,
  getAdminTickets,
  getCurrentUserTickets,
  getTicketById,
  getTicketMessages,
  putTicket
} from '../core/tickets';
import { showToast } from '../utils/toast';

export const handleGetTicketById = async (id: any) => {
  try {
    const { data, status } = await getTicketById(id);
    if (status === 200) {
      return { data: data.Data[0], ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: {}, ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetTicketMessages = async (id: any) => {
  try {
    const { data, status } = await getTicketMessages(id);
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetCurrentUserTickets = async () => {
  try {
    const { data, status } = await getCurrentUserTickets();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    console.log(e);
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handleGetAdminTickets = async () => {
  try {
    const { data, status } = await getAdminTickets();
    if (status === 200) {
      return { data: data.Data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e);
    if (e.response?.status === 404) {
      console.log('not submitted');
      return { data: [], ok: false };
    }
    showToast(e.response?.data?.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePostTicketMessage = async (payload: any) => {
  try {
    const { data, status } = await createTicketMessage(payload);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
export const handlePutTicket = async (stat: any, id: any) => {
  try {
    const { data, status } = await putTicket(stat, id);
    if (status === 200) {
      return { data, ok: true };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};

export const handlePostTicketAndMessage = async (ticketPayload: any, messagePayload: any) => {
  try {
    const { data, status } = await createTicket({ ...ticketPayload, Status: 'PendingViewAdmin' });
    if (status === 200) {
      const ticketID = data.Data.Data.ID;
      console.log(data, ticketID);
      const res = await createTicketMessage({
        ...messagePayload,
        TicketID: ticketID
      });
      if (res.status === 200) {
        return { data: { ID: data.Data.ID }, ok: true };
      }
      return { data: { ID: data.Data.ID }, ok: false };
    } else {
      showToast(data.Message, 'error');
    }
    console.log('NOT 200', data);
  } catch (e: any) {
    console.log(e);
    showToast(e.response?.data.Message?.FA ?? 'مشکلی پیش آمده!', 'error');
  }
  return { ok: false };
};
