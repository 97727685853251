import { useEffect, useState } from 'react';
import {
  handleGetRequestedResumesBySeekerUsername,
  handlePutRequestedResume
} from '../../api-handler';
import { Cell, Column, HeaderCell, Table } from 'rsuite-table';
import { FormatDateTime, RESUME_REQ_STATUS_BY_USER, sortData } from '../../utils/helpers';
import { Button, Loader, Tooltip, Whisper } from 'rsuite';
// import { useNavigate } from 'react-router-dom';
import { faIR } from 'date-fns-jalali/locale';

export const RequestedResumeForJobSeeker = () => {
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>([]);
  // const [loading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('ID');
  const [sortType, setSortType] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const res = await handleGetRequestedResumesBySeekerUsername();
      if (res.ok) {
        setData(res.data);
      }
      setTableLoading(false);
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSortColumn = (sc: any, st: any) => {
    setTableLoading(true);
    setTimeout(() => {
      setTableLoading(false);
      setSortColumn(sc);
      setSortType(st);
    }, 500);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAcceptCvReq = async (id: any, status: any) => {
    setButtonLoading(true);
    const res = await handlePutRequestedResume(id, status);
    if (res.ok) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newData = data.map((cv: any) => {
        if (cv.ID !== id) {
          return cv;
        }
        const newCv = { ...cv };
        newCv.Status = status;
        return newCv;
      });

      setData(newData);
    }
    setButtonLoading(false);
  };

  return (
    <>
      <h4 className="text-h4">درخواست های مشاهده رزومه</h4>
      <p className="bg-[--rs-primary-50] text-[--rs-primary-500] rounded-md border-[1px] my-2 border-[--rs-primary-300] p-3">
        در صورت تمایل به فرصت‌های شغلی جدید، می‌توانید درخواست مشاهده رزومه را تأیید کنید تا
        کارفرمایان به اطلاعات شما دسترسی داشته باشند.
      </p>
      <Table
        data={sortData(data, sortColumn, sortType)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={tableLoading}
        // affixHeader
        renderLoading={() => (
          <div className="flex items-center justify-center h-[100%]">
            <Loader size="md" />
          </div>
        )}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-[100%]">درخواستی موجود نیست</div>
        )}
        autoHeight
        affixHorizontalScrollbar>
        <Column width={100} verticalAlign="middle" align="center">
          <HeaderCell>شماره</HeaderCell>
          <Cell dataKey="ID" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام کاربری</HeaderCell>
          <Cell dataKey="JobSeekerUsername" />
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>نام و نام خانوادگی</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <> {rowData.JobSeekerUsernameInfo}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} verticalAlign="middle" align="center">
          <HeaderCell>زمان</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{FormatDateTime(rowData.TimeStamp, faIR)}</>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} minWidth={200} verticalAlign="middle" align="center">
          <HeaderCell>وضعیت</HeaderCell>
          <Cell dataKey="">
            {(rowData) => {
              return <>{RESUME_REQ_STATUS_BY_USER[rowData.Status]}</>;
            }}
          </Cell>
        </Column>
        <Column width={200} verticalAlign="middle" align="center">
          <HeaderCell>تعیین وضعیت</HeaderCell>
          <Cell dataKey="">
            {(_rowData) => (
              <>
                <Button
                  className="w-[50px] mx-1"
                  appearance="primary"
                  size="xs"
                  type="button"
                  color="green"
                  onClick={() => {
                    handleAcceptCvReq(_rowData.ID, 'EPPending');
                  }}
                  loading={buttonLoading}
                  disabled={!(_rowData.Status === 'JSPending')}>
                  تایید
                </Button>
                <Whisper
                  placement="top"
                  controlId={`topic-hover-${_rowData.ID}`}
                  trigger="hover"
                  speaker={<Tooltip>دوبار پشت سر هم کلیک کنید</Tooltip>}>
                  <Button
                    className="w-[50px] mx-1"
                    appearance="primary"
                    size="xs"
                    type="button"
                    color="red"
                    onDoubleClick={() => {
                      handleAcceptCvReq(_rowData.ID, 'Rejected');
                    }}
                    loading={buttonLoading}
                    disabled={!(_rowData.Status === 'JSPending')}>
                    رد
                  </Button>
                </Whisper>
              </>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  );
};
