import { Button, Checkbox, Col, Form, Rate, Schema } from 'rsuite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlexboxGrid } from 'rsuite';

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  PublicReport: StringType('').isRequired('الزامی است!'),
  PrivateReport: StringType('').isRequired('الزامی است!'),
  Score: NumberType('').isRequired('الزامی است!')
});

import { useNavigate } from 'react-router-dom';
import Field from '../../components/form/field';
import TextAreaField from '../../components/form/textarea-field';
import { useParams } from 'react-router-dom';
import { showToast } from '../../utils/toast';
import { handleGetConsultReportByUser, handlePostConsultReportByUser } from '../../api-handler';

export const ConsulteeMeetingReport = ({ isAdmin }: { isAdmin?: boolean }) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>(null);
  const [formError, setFormError] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formValue, setFormValue] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { id, consultantUsername, counselorUsername } = useParams();
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [survey, setSurvey] = useState<any>({
    Pros: {
      p1: 0,
      p2: 0,
      p3: 0,
      p4: 0,
      p5: 0,
      p6: 0,
      p7: 0,
      p8: 0
    },
    Cons: {
      c1: 0,
      c2: 0,
      c3: 0,
      c4: 0,
      c5: 0,
      c6: 0,
      c7: 0,
      c8: 0
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSurveyChange = (pros: boolean, xNumber: any, value: boolean) => {
    const copySurvey = { ...survey };
    copySurvey[pros ? 'Pros' : 'Cons'][xNumber] = value;
    setSurvey(copySurvey);
  };
  const handleSubmit = useCallback(async () => {
    if (formRef.current && !formRef.current.check()) {
      console.log('formError', formError);
      return;
    }

    if (!formValue) return;
    const payload = {
      ...formValue,
      ConsultID: id,
      Survey: JSON.stringify(survey).replaceAll('"', "'"),
      ConsultantUsername: consultantUsername,
      CounselorUsername: counselorUsername
    };
    setLoading(true);
    const res = await handlePostConsultReportByUser(payload);
    if (res.ok) {
      showToast('نظر شما با موفقیت ثبت شد!', 'success');
      navigate('/user/requests');
    }
    setLoading(false);
  }, [formValue, formError, formRef, id, survey]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await handleGetConsultReportByUser(parseInt(id ?? '-1'));
      if (res.ok) {
        setFormValue(res.data);
        // dat);
        // todo survey check
        setSurvey(JSON.parse(res.data.Survey.replaceAll("'", '"')));
        setEditMode(true);
      }
      setLoading(false);
    })();
  }, [id]);

  return (
    <>
      <h4 className="text-h4">
        {isAdmin ? 'نظر کاربر' : 'نتیجه جلسه تان را با ما به اشتراک بگذارید'}
      </h4>

      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formError={formError}
        formValue={formValue}
        model={model}
        onSubmit={handleSubmit}
        checkTrigger="change"
        className="mt-6 text-dark"
        readOnly={loading || editMode}>
        <FlexboxGrid>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="PublicReport"
              label="نظر خصوصی (فقط توسط ادمین قابل مشاهده است)"
              dir="rtl"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <TextAreaField
              name="PrivateReport"
              label="نظر عمومی (در قسمت نظرات نمایش داده می شود)"
              dir="rtl"
              full
              placeholder=""
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} md={12} lg={8} className="max-w-[100%]">
            <Field name="Score" label="امتیاز کلی" full accepter={Rate} color="yellow" />
          </FlexboxGrid.Item>

          {survey ? (
            <>
              <FlexboxGrid.Item
                as={Col}
                colspan={24}
                sm={24}
                md={12}
                lg={12}
                className="max-w-[100%]">
                نکات مثبت
                <Field
                  name="p1"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p1', checked);
                  }}
                  label=""
                  checked={survey.Pros.p1}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  دانش کافی مشاور
                </Field>
                <Field
                  name="p2"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p2', checked);
                  }}
                  label=""
                  checked={survey.Pros.p2}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  تجربه و مهارت در زمینه تخصصی مشاوره
                </Field>
                <Field
                  name="p3"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p3', checked);
                  }}
                  label=""
                  checked={survey.Pros.p3}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  رعایت اصول حرفه ای مشاوره
                </Field>
                <Field
                  name="p4"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p4', checked);
                  }}
                  label=""
                  checked={survey.Pros.p4}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  ارائه نظرات و پیشنهادهای کاربردی
                </Field>
                <Field
                  name="p5"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p5', checked);
                  }}
                  label=""
                  checked={survey.Pros.p5}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  ارتباط اجتماعی مناسب
                </Field>
                <Field
                  name="p6"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p6', checked);
                  }}
                  label=""
                  checked={survey.Pros.p6}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  رعایت ادب و احترام در جلسه مشاوره
                </Field>
                <Field
                  name="p7"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p7', checked);
                  }}
                  label=""
                  checked={survey.Pros.p7}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  رعایت زمان بندی و استفاده بهینه از زمان جلسه مشاوره
                </Field>
                <Field
                  name="p8"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(true, 'p8', checked);
                  }}
                  label=""
                  checked={survey.Pros.p8}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  درک صحیح از مشکل شرکت
                </Field>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item
                as={Col}
                colspan={24}
                sm={24}
                md={12}
                lg={12}
                className="max-w-[100%]">
                نکات منفی
                <Field
                  name="c1"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c1', checked);
                  }}
                  label=""
                  checked={survey.Cons.c1}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  نداشتن دانش کافی مشاور
                </Field>
                <Field
                  name="c2"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c2', checked);
                  }}
                  label=""
                  checked={survey.Cons.c2}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  عدم تجربه و مهارت در زمینه تخصصی
                </Field>
                <Field
                  name="c3"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c3', checked);
                  }}
                  label=""
                  checked={survey.Cons.c3}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  رعایت نکردن اصول حرفه ای مشاوره و حقوق افراد در جلسه
                </Field>
                <Field
                  name="c4"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c4', checked);
                  }}
                  label=""
                  checked={survey.Cons.c4}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  پیشنهاد کاربردی و مفیدی ارائه نگردید
                </Field>
                <Field
                  name="c5"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c5', checked);
                  }}
                  label=""
                  checked={survey.Cons.c5}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  مشاور، در برقراری ارتباط اجتماعی ضعف داشت
                </Field>
                <Field
                  name="c6"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c6', checked);
                  }}
                  label=""
                  checked={survey.Cons.c6}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  بی احترامی و بی ادبی در حین مشاوره
                </Field>
                <Field
                  name="c7"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c7', checked);
                  }}
                  label=""
                  checked={survey.Cons.c7}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  رعایت نکردن زمان بندی جلسه و عدم استفاده بهینه از زمان جلسه مشاوره
                </Field>
                <Field
                  name="c8"
                  onChange={(value: string | number, checked: boolean) => {
                    handleSurveyChange(false, 'c8', checked);
                  }}
                  label=""
                  checked={survey.Cons.c8}
                  accepter={Checkbox}
                  dir="rtl"
                  full>
                  نداشتن توانایی در درک مشکل شرکت
                </Field>
              </FlexboxGrid.Item>
            </>
          ) : null}
        </FlexboxGrid>
        {isAdmin ? null : (
          <div className="flex justify-between">
            <Button
              appearance="primary"
              className="mr-auto mt-8"
              type="submit"
              loading={loading}
              disabled={editMode}>
              ثبت نتیجه
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default ConsulteeMeetingReport;
